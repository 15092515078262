import React from "react";
import { Container, Row, Col } from "reactstrap";
import CardWrapper from "./CardGridWrapper";
import ArticleBox from "./ArticleBox";
import BlogBox from "./BlogBox";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const Actions = (props) => {
    const actions = props.actions;
    const moreUrl = props.urlParams ? `results?${props.urlParams}` : "results";

    const noEntriesMessage =
        props.url === "blog" ? "Δε βρέθηκαν άρθρα" : "Δε βρέθηκαν δράσεις";
    return (
        <section
            className={`${props.className || ""} agency blog blog-sec"`}
            id={props.id}
        >
            <Container>
                <Row>
                    <Col lg="6" md="8" className="offset-lg-3 offset-md-2">
                        <div className="title">
                            <div className="main-title">
                                <h2
                                    className={
                                        props.headerColor === "white"
                                            ? "white-color"
                                            : ""
                                    }
                                >
                                    {props.title}
                                </h2>
                            </div>
                            <div className="sub-title">
                                <p
                                    className={
                                        props.headerColor === "white"
                                            ? "white-color"
                                            : ""
                                    }
                                >
                                    Όλα τα τελευταία νέα για τα χρημοτοδοτικά
                                    εργαλεία των επιχειρήσεων
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="boxes-row">
                    {actions === null && (
                        <Row>
                            <Col sm="12">
                                <p style={{ textAlign: "center" }}>
                                    Loading...
                                </p>
                            </Col>
                        </Row>
                    )}
                    {actions && actions.length === 0 && (
                        <Row>
                            <Col sm="12">
                                <p style={{ textAlign: "center" }}>
                                    {noEntriesMessage}
                                </p>
                            </Col>
                        </Row>
                    )}
                    {actions &&
                        actions.length > 0 &&
                        actions.map((item, index) => (
                            props.url === "blog" ?
                                <BlogBox
                                    key={`grid-no-sidebar-${index}`}
                                    action={item}
                                    url={props.url}
                                /> :
                                <ArticleBox
                                    key={`grid-no-sidebar-${index}`}
                                    action={item}
                                    url={props.url}
                                />
        
                        ))}
                </Row>
                {props.url === "blog" && actions && actions.length > 2 && (
                    <div style={{ textAlign: "center" }}>
                        <Link
                            to={"/blogs-results/"}
                            style={{ textDecoration: "none", color: "inherit" }}
                        >
                            <Button
                                disableElevation
                                variant="contained"
                                color="primary"
                                style={{
                                    marginTop: 80,
                                    minWidth: 500,
                                    backgroundColor: "#3a5f88",
                                }}
                            >
                                Περισσoτερα
                            </Button>
                        </Link>
                    </div>
                )}
                {props.url === "page" && actions && actions.length > 2 && (
                    <div style={{ textAlign: "center" }}>
                        <Link
                            to={moreUrl}
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                            }}
                        >
                            <Button
                                disableElevation
                                variant="contained"
                                color="primary"
                                style={{
                                    marginTop: 80,
                                    minWidth: 500,
                                    backgroundColor: "#3a5f88",
                                }}
                            >
                                Περισσοτερα
                            </Button>
                        </Link>
                    </div>
                )}
            </Container>
        </section>
    );
};

export default Actions;
