import React from "react";
import { Container, Row, Col } from "reactstrap";

const ResNovaeInfo = (props) => {
	return (
		<section className="agency agency-content about-bg text-center horizontal-borders">
			<Container>
				<Row>
					<Col xl="10" md="10" className="offset-xl-1 offset-md-1">
						<p>
							<img
								className="img-fluid image-iso"
								style={{ width: 140, marginBottom: 30 }}
								src="/assets/logoRes.png"
								alt="alternative"
							/>
						</p>
						<p className="agency-para">
							H ResNovae Σύμβουλοι Επιχειρήσεων είναι
							πιστοποιημένη κατά ISO 9001:2015 για τις υπηρεσίες
							της , ενώ είναι καταξιωμένη στο τομέα της
							Συμβουλευτικής Επενδύσεων με ποσοστά επιτυχίας στα
							μέτρα του ΕΣΠΑ άνω του 85%.
						</p>
						<p className="agency-para">
							Τηλ. Επικοινωνίας:{" "}
							<a href="tel:+302102718306" target="_blank">
								{" "}
								210 2718306
							</a>
						</p>
						<p className="agency-para">
							E-mail:{" "}
							<a href="mailTo:espa@resnovae.gr" target="_blank">
								espa@resnovae.gr
							</a>
						</p>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default ResNovaeInfo;
