import React, { useEffect, useState, useContext } from "react";
import { Context as AuthContext } from "../context/authenticationProvider";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Dropzone from "react-dropzone-uploader";
import Modal from "@material-ui/core/Modal";
import "react-dropzone-uploader/dist/styles.css";
import api from "../api/api";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useDropzone } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 700,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    index: {
        margin: theme.spacing(1),
        width: 50,
    },
    fileDetails: {
        margin: theme.spacing(1),
        width: 200,
    },
    header: {
        margin: "30px 0 5px 20px",
    },
    submitFileButton: {
        color: "white",
        marginTop: 11,
        width: 150,
        backgroundColor: "var(--primary)",
    },
    modal: {
        display: "flex",
        padding: theme.spacing(1),
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    buttonsFormControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        display: "inline-table",
    },
    submitButton: {
        color: "white",
        margin: 5,
        flex: 1,
        width: 150,
        float: "right",
        backgroundColor: "var(--primary)",
    },
    deleteButton: {
        color: "white",
        margin: 5,
        flex: 1,
        width: 150,
        float: "left",
        backgroundColor: "var(--red)",
    },
}));

const ClientActionForm = () => {
    const clientActionId = window.location.href.split("/").pop();
    const [state, login, logout] = useContext(AuthContext);
    const [clientAction, setClientAction] = useState(null);
    const [action, setAction] = useState(null);
    const [fields, setFields] = useState([]);
    const [client, setClient] = useState(null);
    const [actionFiles, setActionFiles] = useState([]);
    const [fieldValues, setFieldValues] = useState([]);
    const [filesForUploads, setFilesForUploads] = useState([]);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [fileList, setFileList] = useState([]);
    const extraOptions = [
        "Προς Υποβολή",
        "Σε Γνωμοδοτική Ένταξης",
        "Εγκεκριμένη",
        "Απορριφθείσα",
        "Τροποποίηση (Προεραιτικό)",
        "Α΄Αίτημα Ελέγχου",
        "Τροποποίηση (Προεραιτικό)",
        "Β’ Αίτημα Ελέγχου",
        "Τροποποίηση (Προεραιτικό)",
        "Τελικό Αίτημα Ελέγχου",
        "Τροποποίηση (Προεραιτικό)",
        "Ολοκλήρωση",
    ];

    const classes = useStyles();

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const updateFileMetadata = (file, field, value) => {
        const newList = [...actionFiles];
        const index = actionFiles.indexOf(file);
        newList[index][field] = value;
        setActionFiles(newList);
    };

    const files = fileList.map((file, index) => (
        <div key={file.path}>
            <FormControl className={classes.index}>
                <TextField label="Α/Α" disabled value={index + 1} />
            </FormControl>
            <FormControl className={classes.fileDetails}>
                <TextField
                    label="Αρχείο"
                    disabled
                    value={`${file.path} - ${file.size} bytes`}
                />
            </FormControl>
            <FormControl className={classes.fileDetails}>
                <TextField label="Τίτλος Αρχείου" />
            </FormControl>
            <FormControl className={classes.fileDetails}>
                <TextField label="Περιγραφή Αρχείου" />
            </FormControl>
        </div>
    ));

    const getClientAction = async () => {
        try {
            const response = await api.get(
                `/EsClientActions/${clientActionId}`,
                {
                    headers: {
                        Authorization: "Bearer " + state.token.accessToken,
                    },
                }
            );
            setClientAction(response.data.clientAction);
            setAction(response.data.action[0]);
            setClient(response.data.client);
            let fields = response.data.fields.sort((a, b) => a.order - b.order);
            let fieldValues = response.data.fieldValues;
            fieldValues.forEach((fieldValue) => {
                const field = fields.find(
                    (field) => field.id === fieldValue.fieldId
                );
                field.value = fieldValue.value;
            });
            setFields(fields);
            setFieldValues(fieldValues);
            setActionFiles(response.data.files);
        } catch (err) {
            alert(err);
        }
    };

    useEffect(() => {
        getClientAction();
    }, []);

    useEffect(() => {
        setFileList([...fileList.concat(acceptedFiles)]);
        setFiles(new Set([...Array.from(acceptedFiles)]));
    }, [acceptedFiles]);

    console.log(fileList, acceptedFiles);

    const updateFieldValue = (field, value) => {
        const fieldLists = [...fields];
        const index = fieldLists.indexOf(field);
        fieldLists[index].value = value;
        setFields(fieldLists);
    };

    const updateLogs = (value) => {
        const obj = { ...clientAction };
        obj.logs = value;
        setClientAction(obj);
    };

    const submitForm = async () => {
        try {
            for (const field of fields) {
                const fieldValue = fieldValues.find(
                    (fieldValue) => fieldValue.fieldId === field.id
                );
                if (fieldValue === undefined) {
                    const data = {
                        fieldId: field.id,
                        clientActionId: clientAction.id,
                        value: field.value,
                    };
                    const response = await api.post("/EsFieldValues", data, {
                        headers: {
                            Authorization: "Bearer " + state.token.accessToken,
                        },
                    });
                } else {
                    const data = {
                        id: fieldValue.id,
                        fieldId: field.id,
                        clientActionId: clientAction.id,
                        value: field.value,
                    };
                    const response = await api.put(
                        `/EsFieldValues/${fieldValue.id}`,
                        data,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + state.token.accessToken,
                            },
                        }
                    );
                }
            }
            // Update logs
            const data = {
                id: clientAction.id,
                clientId: clientAction.clientId,
                actionId: clientAction.actionId,
                logs: clientAction.logs,
            };
            const response = await api.put(
                `/EsClientActions/${clientAction.id}`,
                data,
                {
                    headers: {
                        Authorization: "Bearer " + state.token.accessToken,
                    },
                }
            );
            await uploadFiles();
            await updateFiles();
            setAlertMsg("Η επεξεργασία της Υποβολής ολοκληρώθηκε με επιτυχία");
            setShowAlert(true);
        } catch (err) {
            alert(err);
        }
    };

    const setFiles = async (files) => {
        const list = [...filesForUploads];
        for (const file of files) {
            const filenames = filesForUploads.map((img) => img.filename);
            let reader = new FileReader();
            reader.onload = async function () {
                console.log(file)
                try {
                    const data = {
                        filename: file.path,
                        name: file.name ? file.name : "",
                        description: file.description ? file.description : "",
                        size: file.size,
                        fileType: file.type,
                        extension: file.name.substring(
                            file.name.lastIndexOf(".") + 1
                        ),
                        displayOrder: 1,
                        comments: "IMG Post",
                        content: {
                            tnid: 1,
                            fileType: file.type,
                            extension: file.name.substring(
                                file.name.lastIndexOf(".") + 1
                            ),
                            content: reader.result.split(",")[1],
                        },
                    };
                    
                    list.push(data);
                } catch (err) {
                    alert(err);
                }
            };
            if (file) {
                await reader.readAsDataURL(file);
            }
        }
        setFilesForUploads(list);
    };

    const uploadFiles = async () => {
        try {
            for (const file of filesForUploads) {
                const data = {
                    name: file.name,
                    description: file.description,
                    filename: file.filename,
                    content: file.content.content,
                    clientActionId,
                };
                const response = await api.post("/EsFiles/", data, {
                    headers: {
                        Authorization: "Bearer " + state.token.accessToken,
                    },
                });
            }
        } catch (err) {
            alert(err);
        }
    };

    const updateFiles = async () => {
        try {
            for (const file of actionFiles) {
                const data = {
                    id: file.id,
                    name: file.name,
                    description: file.description,
                    filename: file.filename,
                    content: file.content,
                    clientActionId,
                };
                const response = await api.put(`/EsFiles/${file.id}`, data, {
                    headers: {
                        Authorization: "Bearer " + state.token.accessToken,
                    },
                });
            }
        } catch (err) {
            alert(err);
        }
    };

    const downloadFile = (id) => {
        window.open(`${api.defaults.baseURL}EsFiles/${id}`);
    };

    const deleteClientAction = async () => {
        const response = await api.delete(`/EsClientActions/${clientActionId}`, {
            headers: {
                Authorization: "Bearer " + state.token.accessToken,
            },
        });
        setAlertMsg("Η Υποβολή Πελάτη διαγράφηκε με επιτυχία");
        setShowAlert(true);
    };

    return (
        <>
            <h4 className={classes.header}>Στοιχεία Δράσης</h4>
            <div className="col-md-6 col-12">
                <FormControl className={classes.formControl}>
                    <TextField
                        label="Τίτλος Δράσης"
                        disabled={true}
                        value={action ? action.description : ""}
                    />
                </FormControl>
            </div>
            <div className="col-md-6 col-12">
                <FormControl className={classes.formControl}>
                    <TextField
                        label="Φορέας Δράσης"
                        value={action ? action.authority : ""}
                        disabled={true}
                    />
                </FormControl>
            </div>
            <div className="col-md-6 col-12">
                <FormControl className={classes.formControl}>
                    <TextField
                        label="Επιχειρησιακό/Τομεακό Πρόγραμμα"
                        value={action ? action.program : ""}
                        disabled={true}
                    />
                </FormControl>
            </div>
            <h4 className={classes.header}>Στοιχεία Πελάτη</h4>
            <div className="col-md-6 col-12">
                <FormControl className={classes.formControl}>
                    <TextField
                        label="Επωνυμία Επιχείρησης / Ονοματεπώνυμο"
                        disabled={true}
                        value={client ? client.name : ""}
                    />
                </FormControl>
            </div>
            <div className="col-md-6 col-12">
                <FormControl className={classes.formControl}>
                    <TextField
                        label="Τηλέφωνο"
                        value={client ? client.phone : ""}
                        disabled={true}
                    />
                </FormControl>
            </div>
            <div className="col-md-6 col-12">
                <FormControl className={classes.formControl}>
                    <TextField
                        label="Email"
                        value={client ? client.email : ""}
                        disabled={true}
                    />
                </FormControl>
            </div>
            <h4 className={classes.header}>
                Στοιχεία Επενδυτικού Σχεδίου Πελάτη
            </h4>
            {fields.map((field, index) =>
                field.label !== "Τρέχουσα Κατάσταση" ? (
                    <div className="col-md-12 col-12" key={index}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                type={field.type}
                                label={field.label}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={field.value ? field.value : ""}
                                onChange={(event) =>
                                    updateFieldValue(field, event.target.value)
                                }
                            />
                        </FormControl>
                    </div>
                ) : (
                    <div className="col-md-12 col-12">
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-helper-label">
                                {field.label}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={field.value}
                                onChange={(event) =>
                                    updateFieldValue(field, event.target.value)
                                }
                            >
                                {extraOptions.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                )
            )}

            <div className="col-md-6 col-12">
                <FormControl className={classes.formControl}>
                    <TextField
                        label="Logs - Παρατηρήσεις"
                        multiline
                        onChange={(event) => updateLogs(event.target.value)}
                        value={clientAction ? clientAction.logs : ""}
                    />
                </FormControl>
            </div>

            <h4 className={classes.header}>Αρχεία Υποβολής</h4>
            <div className="col-12">
                {actionFiles.map((file, index) => (
                    <div key={index}>
                        <FormControl className={classes.index}>
                            <TextField label="Α/Α" disabled value={index + 1} />
                        </FormControl>
                        <FormControl className={classes.fileDetails}>
                            <TextField
                                label="Αρχείο"
                                disabled
                                value={file.filename ? file.filename : ""}
                            />
                        </FormControl>
                        <FormControl className={classes.fileDetails}>
                            <TextField
                                label="Τίτλος Αρχείου"
                                value={file.name ? file.name : ""}
                                onChange={(event) =>
                                    updateFileMetadata(
                                        file,
                                        "name",
                                        event.target.value
                                    )
                                }
                            />
                        </FormControl>
                        <FormControl className={classes.fileDetails}>
                            <TextField
                                label="Περιγραφή Αρχείου"
                                value={file.description ? file.description : ""}
                                onChange={(event) =>
                                    updateFileMetadata(
                                        file,
                                        "description",
                                        event.target.value
                                    )
                                }
                            />
                        </FormControl>
                        <FormControl className={classes.fileDetails}>
                            <Button
                                className={classes.submitFileButton}
                                color="primary"
                                onClick={() => downloadFile(file.id)}
                                Large
                            >
                                Download
                            </Button>
                        </FormControl>
                    </div>
                ))}
            </div>

            <h4 className={classes.header}>Προσθήκη Αρχείων Υποβολής</h4>
            <section className="container">
                <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>
                        Drag 'n' drop some files here, or click to select files
                    </p>
                </div>
                <aside>
                    <ul>{files}</ul>
                </aside>
            </section>

            <div className="col-md-6 col-12">
                <FormControl className={classes.buttonsFormControl}>
                    <Button
                        color="primary"
                        className={classes.deleteButton}
                        onClick={() => setShowDeleteAlert(true)}
                    >
                        Διαγραφη
                    </Button>
                    <Button
                        color="primary"
                        className={classes.submitButton}
                        onClick={() => submitForm()}
                    >
                        Αποθηκευση
                    </Button>
                </FormControl>
            </div>
            <Modal
                disableEnforceFocus
                disableAutoFocus
                open={showDeleteAlert}
                className={classes.modal}
            >
                <div className={classes.paper}>
                    <h2 id="server-modal-title">Διαγραφή Δράσης Πελάτη</h2>
                    <p id="server-modal-description">
                        Είστε σίγουροι ότι θέλετε να προχωρήσετε στη διαγραφή
                        της Δράσης του Πελάτη;
                    </p>
                    <FormControl className={classes.buttonsFormControl}>
                        <Button
                            color="primary"
                            className={classes.submitButton}
                            onClick={() => setShowDeleteAlert(false)}
                        >
                            Ακυρωση
                        </Button>
                        <Button
                            color="primary"
                            className={classes.deleteButton}
                            onClick={() => {
                                setShowDeleteAlert(false);
                                deleteClientAction();
                            }}
                        >
                            Διαγραφη
                        </Button>
                    </FormControl>
                </div>
            </Modal>
            <Modal
                disableEnforceFocus
                disableAutoFocus
                open={showAlert}
                className={classes.modal}
            >
                <div className={classes.paper}>
                    <h2 id="server-modal-title">Επεξεργασία Δρασης Πελάτη</h2>
                    <p id="server-modal-description">{alertMsg}</p>
                    <FormControl className={classes.buttonsFormControl}>
                        <Button
                            className={classes.submitButton}
                            style={{ width: "100%" }}
                            onClick={() => {
                                setShowAlert(false);
                                window.location.href = "client-actions";
                            }}
                        >
                            OK
                        </Button>
                    </FormControl>
                </div>
            </Modal>
        </>
    );
};

export default ClientActionForm;
