import React from "react";
import { Container, Row, Col } from "reactstrap";
import CardWrapper from "./CardGridWrapper";
import ArticleBox2 from "./ArticleBox2";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const ActiveActions = (props) => {
    const actions = props.actions;
    const moreUrl = props.urlParams ? `results?${props.urlParams}` : "results";
    if (!actions) {
        return <></>;
    }
    return (
        <section
            className={`${props.className || ""} agency blog blog-sec"`}
            id={props.id}
        >
            <Container>
                <Row>
                    <Col lg="6" md="8" className="offset-lg-3 offset-md-2">
                        <div className="title">
                            <div className="main-title">
                                <h2
                                    className={
                                        props.headerColor === "white"
                                            ? "white-color"
                                            : ""
                                    }
                                >
                                    {props.title}
                                </h2>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {actions.length > 0
                        ? actions.map((item, index) => (
                              <ArticleBox2
                                  key={`grid-no-sidebar-${index}`}
                                  action={item}
                                  url={props.url}
                              />
                          ))
                        : "Loading..."}
                </Row>
                {props.url === "page" && (
                    <div style={{ textAlign: "center" }}>
                        <Link
                            to={moreUrl}
                            style={{ textDecoration: "none", color: "inherit" }}
                        >
                            <Button
                                disableElevation
                                variant="contained"
                                color="primary"
                                style={{
                                    minWidth: 500,
                                    backgroundColor: "#54cce4",
                                }}
                            >
                                Περισσoτερα
                            </Button>
                        </Link>
                    </div>
                )}
            </Container>
        </section>
    );
};

export default ActiveActions;
