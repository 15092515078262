import React, { useEffect, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: "100%",
	},
	imageBox: {
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(3),
		minWidth: "100%",
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	index: {
		margin: theme.spacing(1),
		width: 50,
	},
	fileDetails: {
		margin: theme.spacing(1),
		width: 200,
	},
	header: {
		margin: "30px 0 10px 7px",
	},
	buttonsFormControl: {
		margin: theme.spacing(1),
		minWidth: "100%",
		display: "inline-table",
	},
	submitButton: {
		color: "white",
		margin: 5,
		flex: 1,
		width: 150,
		float: "left",
		backgroundColor: "var(--primary)",
	},
	checkBox: {
		padding: theme.spacing(1),
	},
}));

const ActionFilesForm = (props) => {
	const classes = useStyles();
	const updateFileName = (value, index) => {
		const newList = [...props.fileNames];
		newList[index] = value;
		props.setFileNames(newList);
	};
	const updateFileDescription = (value, index) => {
		const newList = [...props.fileDescription];
		newList[index] = value;
		props.setFileDescription(newList);
	};

	const files = props.acceptedFiles.map((file, index) => (
		<div key={file.path}>
			<FormControl className={classes.index}>
				<TextField
					label="Α/Α"
					InputProps={{
						readOnly: true,
					}}
					value={index + 1}
				/>
			</FormControl>
			<FormControl className={classes.fileDetails}>
				<TextField
					label="Αρχείο"
					InputProps={{
						readOnly: true,
					}}
					value={`${file.path} - ${file.size} bytes`}
				/>
			</FormControl>
			<FormControl className={classes.fileDetails}>
				<TextField
					label="Τίτλος Αρχείου"
					onChange={(e) => updateFileName(e.target.value, index)}
				/>
			</FormControl>
			<FormControl className={classes.fileDetails}>
				<TextField
					label="Περιγραφή Αρχείου"
					onChange={(e) =>
						updateFileDescription(e.target.value, index)
					}
				/>
			</FormControl>
		</div>
	));
	return (
		<React.Fragment>
			<div {...props.getRootProps({ className: "dropzone" })}>
				<input {...props.getInputProps()} />
				<p>Drag 'n' drop some files here, or click to select files</p>
			</div>
			<aside>
				<ul>{files}</ul>
			</aside>
			<div className="col-md-6 col-12">
				<FormControl className={classes.buttonsFormControl}>
					<Button
						className={classes.submitButton}
						variant="outlined"
						color="primary"
						onClick={() => props.uploadFiles()}
					>
						ΕΠΟΜΕΝΟ
					</Button>
				</FormControl>
			</div>
		</React.Fragment>
	);
};

export default ActionFilesForm;
