import React from "react";
import DashboardLayout from "./DashboardLayout";
import InfoList from "./InfoList";

export default function Info() {
  return (
    <DashboardLayout title={"Info List"}>
      <InfoList />
    </DashboardLayout>
  );
}
