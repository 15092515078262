import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PeopleIcon from "@material-ui/icons/People";
import CreateIcon from "@material-ui/icons//Create";
import DescriptionIcon from "@material-ui/icons/Description";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import ListIcon from "@material-ui/icons/List";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import WorkIcon from "@material-ui/icons/Work";
import LanguageIcon from "@material-ui/icons/Language"
import EmailIcon from "@material-ui/icons/Email";
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import { Link } from "react-router-dom";

export const mainListItems = (
  <div>
    <Link
      to="/"
      style={{ textDecoration: "none", color: "inherit" }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ListItem button>
        <ListItemIcon>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText primary="Portal" />
      </ListItem>
    </Link>
    <Link
      to="/clients-new"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemIcon>
          <PersonAddIcon />
        </ListItemIcon>
        <ListItemText primary="Νέος Πελάτης" />
      </ListItem>
    </Link>
    <Link
      to="/actions-new"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemIcon>
          <PlaylistAddIcon />
        </ListItemIcon>
        <ListItemText primary="Νέα Δράση" />
      </ListItem>
    </Link>
    <Link
      to="/clients-actions-new"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemIcon>
          <LibraryAddIcon />
        </ListItemIcon>
        <ListItemText primary="Νέα Υποβολή Πελάτη" />
      </ListItem>
    </Link>
    <Link to="/clients" style={{ textDecoration: "none", color: "inherit" }}>
      <ListItem button>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Πελάτες" />
      </ListItem>
    </Link>
    <Link to="/actions" style={{ textDecoration: "none", color: "inherit" }}>
      <ListItem button>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary="Δράσεις" />
      </ListItem>
    </Link>
    <Link
      to="/client-actions"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemIcon>
          <WorkIcon />
        </ListItemIcon>
        <ListItemText primary="Υποβολές Πελατών" />
      </ListItem>
    </Link>
    <Link
      to="/blogs"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText primary="Blog Posts" />
      </ListItem>
    </Link>
    <Link
      to="/blogs-new"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemIcon>
          <CreateIcon />
        </ListItemIcon>
        <ListItemText primary="Νέο Blog Post" />
      </ListItem>
    </Link>
    <Link
      to="/newsletter"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText primary="Νewsletter" />
      </ListItem>
    </Link>
    <Link
      to="/info"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemIcon>
          <LocalPhoneIcon />
        </ListItemIcon>
        <ListItemText primary="Info" />
      </ListItem>
    </Link>

    {
      // <ListItem button>
      //   <ListItemIcon>
      //     <ShoppingCartIcon />
      //   </ListItemIcon>
      //   <ListItemText primary="Orders" />
      // </ListItem>
      // <ListItem button>
      //   <ListItemIcon>
      //     <PeopleIcon />
      //   </ListItemIcon>
      //   <ListItemText primary="Customers" />
      // </ListItem>
      // <ListItem button>
      //   <ListItemIcon>
      //     <BarChartIcon />
      //   </ListItemIcon>
      //   <ListItemText primary="Reports" />
      // </ListItem>
      // <ListItem button>
      //   <ListItemIcon>
      //     <LayersIcon />
      //   </ListItemIcon>
      //   <ListItemText primary="Integrations" />
      // </ListItem>
    }
  </div>
);

export const secondaryListItems = null;

// export const secondaryListItems = (
//   <div>
//     <ListSubheader inset>Saved reports</ListSubheader>
//     <ListItem button>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Current month" />
//     </ListItem>
//     <ListItem button>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Last quarter" />
//     </ListItem>
//     <ListItem button>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Year-end sale" />
//     </ListItem>
//   </div>
// );
