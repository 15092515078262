import React from "react";
import DashboardLayout from "./DashboardLayout";
import ActionForClientForm from "./ActionForClientForm";

export default function NewAction() {

  const [loadingMessage, setLoadingMessage] = React.useState("Λήψη Δεδομένων");
  const [loading, setLoading] = React.useState(true);

  return (
    <DashboardLayout
      title={"Νέα Υποβολή Πελάτη"}
      loading={loading}
      loadingMessage={loadingMessage}
    >
      <ActionForClientForm setLoadingMessage={setLoadingMessage} setLoading={setLoading} />
    </DashboardLayout>
  );
}
