import React, { useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import api from "../../api/api";

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: "100%",
	},
	index: {
		margin: theme.spacing(1),
		width: 50,
	},
	fileDetails: {
		margin: theme.spacing(1),
		width: "20%",
	},
	buttonsFormControl: {
		margin: theme.spacing(1),
		minWidth: "100%",
		display: "inline-table",
	},
	deleteButton: {
		color: "white",
		margin: 5,
		flex: 1,
		width: 150,
		backgroundColor: "var(--red)",
	},
	submitButton: {
		color: "white",
		margin: 5,
		flex: 1,
		width: 150,
		backgroundColor: "var(--primary)",
	},
	link: {
		lineHeight: "60px",
	},
}));

const ManageActionFiles = (props) => {
	const classes = useStyles();
	const fileDownload = async (id) => {
		var win = window.open('');
		try {
			const response = await api.get(`/DownloadFile/${id}`, {
				responseType: "blob",
			});
			var url = window.URL.createObjectURL(response.data, {
				oneTimeOnly: true,
			});
			win.location = url;
		} catch (err) {
			alert(err);
		}
	};
	return (
		<React.Fragment>
			{" "}
			<div className="col-12">
				{props.files.map((file, index) => (
					<div key={index}>
						<FormControl className={classes.index}>
							<TextField
								label="Α/Α"
								size="small"
								InputProps={{
									readOnly: true,
								}}
								value={index + 1}
							/>
						</FormControl>
						<FormControl className={classes.fileDetails}>
							<TextField
								label="Αρχείο"
								size="small"
								InputProps={{
									readOnly: true,
								}}
								value={file.filename ? file.filename : ""}
							/>
						</FormControl>
						<FormControl className={classes.fileDetails}>
							<TextField
								label="Τίτλος Αρχείου"
								size="small"
								value={file.name ? file.name : ""}
								InputProps={{
									readOnly: true,
								}}
							/>
						</FormControl>
						<FormControl className={classes.fileDetails}>
							<TextField
								label="Περιγραφή Αρχείου"
								size="small"
								value={file.description ? file.description : ""}
								InputProps={{
									readOnly: true,
								}}
							/>
						</FormControl>
						<Button
							color="primary"
							variant="contained"
							onClick={() => fileDownload(file.id)}
							className={classes.submitButton}
						>
							Download
						</Button>
						<Button
							color="primary"
							variant="contained"
							className={classes.deleteButton}
							onClick={() => {
								props.deleteFile(file.id);
							}}
						>
							Διαγραφη
						</Button>
					</div>
				))}
			</div>
		</React.Fragment>
	);
};

export default ManageActionFiles;
