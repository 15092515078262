import React from "react";
import DashboardLayout from "../DashboardLayout";
import ActionForm from "./ActionForm";

export default function NewAction() {
	const [loadingMessage, setLoadingMessage] = React.useState("Loading");
	const [loading, setLoading] = React.useState(false);
	return (
		<DashboardLayout
			title={"Νέα Δράση"}
			loading={loading}
			loadingMessage={loadingMessage}
		>
			<ActionForm
				setLoadingMessage={setLoadingMessage}
				setLoading={setLoading}
			/>
		</DashboardLayout>
	);
}
