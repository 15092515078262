import React from "react";
import DashboardLayout from "./DashboardLayout";
import EditBlogForm from "./EditBlogForm";

export default function NewAction() {
  return (
    <DashboardLayout title={"Επεξεργασία Blog Post"}>
      <EditBlogForm />
    </DashboardLayout>
  );
}
