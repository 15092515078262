import React, { useEffect, useState } from "react";
import Footer from "./common/footer";
import HomeHeader from "./HomeHeader";
import SearchActions from "./SearchActions";
import ResNovaeInfo from "./common/ResNovaeInfo";
import CommunicationForm from "./CommunicationForm";
import api from "../api/api";

const ResultsPage = () => {
	const url = new URL(window.location.href);
	const params = url.search;
	const [companySizes, setCompanySizes] = useState([]);
	const [companyServices, setCompanyServices] = useState([]);
	const [regions, setRegions] = useState([]);
	const [actions, setActions] = useState(null);

	const getCompanyServices = async () => {
		try {
			const response = await api.get("/EsCompanyServices");
			setCompanyServices(response.data);
		} catch (err) {
			alert(err);
		}
	};

	const getCompanySizes = async () => {
		try {
			const response = await api.get("/EsCompanySize");
			setCompanySizes(response.data);
		} catch (err) {
			alert(err);
		}
	};

	const getRegions = async () => {
		try {
			const response = await api.get("/EsRegions");
			setRegions(response.data);
		} catch (err) {
			alert(err);
		}
	};

	const getActions = async () => {
		try {
			const response = await api.get(`/Search${params}`);
			response.data.forEach((item, index) => {
				const today = new Date();
				const endSubmitDate = new Date(item.endSubmitDate);
				if (endSubmitDate > today) {
					item.isOpenForSubmision = true;
				}
				item.index = index + 1;
			});
			// const detailsResponse = await api.get("/EsActionDetail");
			// response.data.forEach((item) => {
			// 	const details = detailsResponse.data.find(
			// 		(dt) => dt.actionId === item.id
			// 	);
			// 	item.details = details
			// 		? details
			// 		: { slider: false, active: false, portal: false };
			// });
			setActions(response.data);
			window.scrollTo(0, 0);
		} catch (err) {
			alert(err);
		}
	};

	useEffect(() => {
		getActions();
		getCompanyServices();
		getCompanySizes();
		getRegions();
	}, []);

	return (
		<React.Fragment>
			<HomeHeader className="app1" />
			<SearchActions
				id="draseis"
				title="Αποτελέσματα αναζήτησης"
				className="active-actions-section results"
				actions={actions}
				url="page"
			/>
			<ResNovaeInfo />
			<CommunicationForm
				id="form"
				regions={regions}
				title={"Φόρμα Επικοινωνίας"}
				subTitle={
					"Συμπληρώστε τα στοιχεία και σύντομα ένας εκπρόσωπός μας θα επικοινωνήσει μαζί σας."
				}
				companySizes={companySizes}
				companyServices={companyServices}
			/>
			<Footer />
		</React.Fragment>
	);
};

export default ResultsPage;
