import React, { useEffect, useState, useContext } from "react";
import { Context as AuthContext } from "../../context/authenticationProvider";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import api from "../../api/api";
import { makeStyles } from "@material-ui/core/styles";
import "react-dropzone-uploader/dist/styles.css";
import { useDropzone } from "react-dropzone";
import ActionBacicForm from "./ActionBacicForm";
import ActionDetailsForm from "./ActionDetailsForm";
import ActionFieldsForm from "./ActionFieldsForm";
import ActionFilesForm from "./ActionFilesForm";
import ManageActionFiles from "./ManageActionFiles";
import ImageBox from "../../components/ImageBox";
import FormModal from "../FormModal";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useHistory } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
    },
    imageBox: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        minWidth: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    index: {
        margin: theme.spacing(1),
        width: 50,
    },
    fileDetails: {
        margin: theme.spacing(1),
        width: 200,
    },
    header: {
        margin: "30px 0 10px 7px",
    },
    buttonsFormControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        display: "inline-table",
    },
    submitButton: {
        color: "white",
        margin: 5,
        flex: 1,
        width: 150,
        float: "left",
        backgroundColor: "var(--primary)",
    },
    checkBox: {
        padding: theme.spacing(1),
    },
}));

const ActionForm = (props) => {
    const [step, setStep] = useState(0);
    const history = useHistory();
    const handleChange = (event, newValue) => {
        setStep(newValue);
    };
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const [action, setAction] = useState(props.action);
    const [actionBasicState, setActionBasicState] = useState([]);
    const [detailsState, setDetailsState] = useState([]);
    const [actionDetails, setActionDetails] = useState(props.actionDetails);

    const [state, login, logout] = useContext(AuthContext);

    const [filesForUploads, setFilesForUploads] = useState([]);
    const [fields, setFields] = useState([]);
    const [actionId, setActionId] = useState(null);

    const [companyServices, setCompanyServices] = useState([]);
    const [companySizes, setCompanySizes] = useState([]);
    const [regions, setRegions] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");

    const [fileNames, setFileNames] = useState([]);
    const [fileDescription, setFileDescription] = useState([]);

    const [files, setFiles] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const classes = useStyles();
    
    useEffect(() => {
        setFileNames([]);
        setFileDescription([]);
    }, [acceptedFiles]);

    const getFiles = async () => {
        props.setLoadingMessage('Φορτωση Αρχείων Δράσης');
        props.setLoading(true);
        try {
            const response = await api.get(`/GetActionFiles/${action.id}`);
            setFiles(response.data);
        } catch (err) {
            alert(err);
        }
        props.setLoading(false);
    };

    useEffect(() => {
        step === 3 && getFiles();
    }, [step]);

    const getCompanyServices = async () => {
        try {
            const response = await api.get("/EsCompanyServices");
            setCompanyServices(response.data);
        } catch (err) {
            alert(err);
        }
    };

    const getCompanySizes = async () => {
        try {
            const response = await api.get("/EsCompanySize");
            setCompanySizes(response.data);
        } catch (err) {
            alert(err);
        }
    };

    const getRegions = async () => {
        try {
            const response = await api.get("/EsRegions");
            setRegions(response.data);
        } catch (err) {
            alert(err);
        }
    };

    useEffect(() => {
        getCompanyServices();
        getCompanySizes();
        getRegions();
    }, []);

    const getFields = async () => {
        try {
            const response = await api.get("/EsFields", {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                },
            });
            setFields(response.data);
        } catch (err) {
            alert(err);
        }
    };

    useEffect(() => {
        login();
        getFields();
    }, []);

    const submitForm = async () => {

        props.setLoadingMessage('Δημιουργία Δράσης');
        props.setLoading(true);
        const getValueByName = (name) => {
            return actionBasicState.filter((item) => item.name === name)[0]
                .value;
        };
        try {
            const formData = new FormData();
            actionBasicState.forEach((field) => {
                field.autoFormData &&
                    formData.append(field.name, getValueByName(field.name));
            });
            const imageItem = actionBasicState.filter(
                (item) => item.name === "image"
            )[0];
            if (imageItem && imageItem.value) {
                formData.append("image", imageItem.value[0]);
            }
            const response = await api.post("/EsActions", formData, {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                    "Content-Type": "multipart/form-data",
                },
            });
            setAction(response.data);
            const detailsFormData = new FormData();
            detailsFormData.append("actionId", response.data.id);
            const detailsResponse = await api.post(
                "/EsActionDetails",
                detailsFormData,
                {
                    headers: {
                        Authorization: "Bearer " + state.token.accessToken,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            setActionDetails(detailsResponse.data);
            setStep(1);
        } catch (err) {
            setAlertMsg(err);
            setShowModal(true);
        }
        props.setLoading(false);
    };

    const putAction = async () => {
        props.setLoadingMessage('Ενημέρωση Δράσης');
        props.setLoading(true);
        const getValueByName = (name) => {
            return actionBasicState.filter((item) => item.name === name)[0]
                .value;
        };
        try {
            const formData = new FormData();
            formData.append("id", action.id);
            actionBasicState.forEach((field) => {
                field.autoFormData &&
                    formData.append(field.name, getValueByName(field.name));
            });
            const imageItem = actionBasicState.filter(
                (item) => item.name === "image"
            )[0];
            if (imageItem && imageItem.value) {
                formData.append(
                    "image",
                    typeof imageItem.value === "string"
                        ? imageItem.value
                        : imageItem.value[0]
                );
            }
            const response = await api.put(
                `/EsActions/${action.id}`,
                formData,
                {
                    headers: {
                        Authorization: "Bearer " + state.token.accessToken,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            setAction(response.data);
            setStep(1);
        } catch (err) {
            setAlertMsg(err);
            setShowModal(true);
        }
        props.setLoading(false);
    };

    const postActionFields = async () => {
        try {
            const actionId = action.id;
            const enabledFields = fields.filter(
                (field) => field.enabled === true
            );
            for (const field of enabledFields) {
                const data = {
                    actionId: actionId,
                    fieldId: field.id,
                };
                const response = await api.post("/EsActionFields", data, {
                    headers: {
                        Authorization: "Bearer " + state.token.accessToken,
                    },
                });
            }
            setAlertMsg("H Δράση δημιουργήθηκε με επιτυχία");
            setShowModal(true);
        } catch (err) {
            alert(err);
        }
        props.setLoading(false);
    };

    const putActionDetails = async () => {
        props.setLoadingMessage('Ενημέρωση Δράσης');
        props.setLoading(true);
        const getValueByName = (name) => {
            return detailsState.filter((item) => item.name === name)[0].value;
        };
        const formData = new FormData();
        formData.append("id", actionDetails.id);
        formData.append("actionId", action.id);
        detailsState.forEach((field) => {
            field.autoFormData &&
                formData.append(field.name, getValueByName(field.name));
        });

        const section2ImageItem = detailsState.filter(
            (item) => item.name === "section2Image"
        )[0];
        if (section2ImageItem && section2ImageItem.value) {
            formData.append(
                "section2Image",
                typeof section2ImageItem.value === "string"
                    ? section2ImageItem.value
                    : section2ImageItem.value[0]
            );
        }
        const section3ImageItem = detailsState.filter(
            (item) => item.name === "section3Image"
        )[0];
        if (section3ImageItem && section3ImageItem.value) {
            formData.append(
                "section3Image",
                typeof section3ImageItem.value === "string"
                    ? section3ImageItem.value
                    : section3ImageItem.value[0]
            );
        }
        const section4ImageItem = detailsState.filter(
            (item) => item.name === "section4Image"
        )[0];
        if (section4ImageItem && section4ImageItem.value) {
            formData.append(
                "section4Image",
                typeof section4ImageItem.value === "string"
                    ? section4ImageItem.value
                    : section4ImageItem.value[0]
            );
        }
        const section5ImageItem = detailsState.filter(
            (item) => item.name === "section5Image"
        )[0];
        if (section5ImageItem && section5ImageItem.value) {
            formData.append(
                "section5Image",
                typeof section5ImageItem.value === "string"
                    ? section5ImageItem.value
                    : section5ImageItem.value[0]
            );
        }

        try {
            const response = await api.put(
                `/EsActionDetails/${actionDetails.id}`,
                formData,
                {
                    headers: {
                        Authorization: "Bearer " + state.token.accessToken,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            setActionDetails(response.data);
            setStep(2);
        } catch (err) {
            alert(err);
        }
        props.setLoading(false);
    };

    const toggleFields = (checked, field) => {
        const fieldsList = [...fields];
        const index = fieldsList.indexOf(field);
        fieldsList[index].enabled = checked;
        setFields(fieldsList);
    };

    const shouldDisplayActionForm = () => {
        return (
            step === 0 &&
            companyServices.length &&
            companySizes.length &&
            regions.length
        );
    };

    const uploadFiles = async () => {
        props.setLoadingMessage('Προετοιμασία Αρχείων');
        props.setLoading(true);
        try {
            for (const [index, file] of acceptedFiles.entries()) {
                const formData = new FormData();
                formData.append("actionId", action.id);
                formData.append(
                    "name",
                    fileNames[index] ? fileNames[index] : ""
                );
                formData.append(
                    "description",
                    fileDescription[index] ? fileDescription[index] : ""
                );
                formData.append("filename", file.path);
                formData.append("content", file);
                props.setLoadingMessage(`Uploading Αρχείου ${file.path}`);
                const response = await api.post("/EsFiles/", formData, {
                    headers: {
                        Authorization: "Bearer " + state.token.accessToken,
                        "Content-Type": "multipart/form-data",
                    },
                });
            }
            setStep(3);
        } catch (err) {
            alert(err);
        }
        props.setLoading(false);
    };

    const deleteAction = async () => {
        confirmAlert({
            title: "Διαγραφή Δράσης",
            message: "Θέλετε να διαγραψετε τη δράση;",
            buttons: [
                {
                    label: "Ναι",
                    onClick: async () => {
                        const response = await api.delete(
                            `/EsActions/${action.id}`,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " + state.token.accessToken,
                                },
                            }
                        );
                        history.push(`/actions/`);
                    },
                },
                {
                    label: "Όχι",
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
        });
    };

    const deleteFile = async (id) => {
        confirmAlert({
            title: "Διαγραφή Αρχείου",
            message: "Θέλετε να διαγραψετε το Αρχείο;",
            buttons: [
                {
                    label: "Ναι",
                    onClick: async () => {
                        props.setLoadingMessage('Διαγραφή Αρχείου');
                        props.setLoading(true);
                        const response = await api.delete(`/EsFiles/${id}`, {
                            headers: {
                                Authorization:
                                    "Bearer " + state.token.accessToken,
                            },
                        });
                        props.setLoading(false);
                        getFiles();
                    },
                },
                {
                    label: "Όχι",
                    onClick: () => alert("Click No"),
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
        });
    };

    return (
        <>
            <Paper square>
                <Tabs
                    value={step}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                >
                    <Tab label="ΒΑΣΙΚΑ ΧΑΡΑΚΤΗΡΙΣΤΙΚΑ" />
                    <Tab label="PORTAL" disabled={!actionDetails} />
                    <Tab label="UPLOAD ΑΡΧΕΙΩΝ" disabled={!actionDetails} />
                    <Tab label="ΑΡΧΕΙΑ" />
                    <Tab
                        label="ΠΕΔΙΑ ΓΙΑ ΥΠΟΒΟΛΕΣ ΠΕΛΑΤΩΝ"
                        disabled={step < 2}
                    />
                </Tabs>
            </Paper>
            {shouldDisplayActionForm() && (
                <React.Fragment>
                    <ActionBacicForm
                        action={action}
                        setActionBasicState={setActionBasicState}
                        companySizes={companySizes}
                        companyServices={companyServices}
                        regions={regions}
                        submitForm={action ? putAction : submitForm}
                        deleteAction={deleteAction}
                    />
                </React.Fragment>
            )}
            {step === 1 && (
                <React.Fragment>
                    <ActionDetailsForm
                        setDetailsState={setDetailsState}
                        actionDetails={actionDetails}
                        putActionDetails={putActionDetails}
                    />
                </React.Fragment>
            )}
            {step === 2 && (
                <React.Fragment>
                    <ActionFilesForm
                        acceptedFiles={acceptedFiles}
                        getRootProps={getRootProps}
                        getInputProps={getInputProps}
                        uploadFiles={uploadFiles}
                        fileNames={fileNames}
                        fileDescription={fileDescription}
                        setFileNames={setFileNames}
                        setFileDescription={setFileDescription}
                    />
                </React.Fragment>
            )}
            {step === 3 && (
                <React.Fragment>
                    <ManageActionFiles
                        files={files}
                        deleteFile={deleteFile}
                    ></ManageActionFiles>
                </React.Fragment>
            )}
            {step === 4 && (
                <ActionFieldsForm
                    fields={fields}
                    toggleFields={toggleFields}
                    postActionFields={postActionFields}
                ></ActionFieldsForm>
            )}
            <FormModal
                showModal={showModal}
                setShowModal={setShowModal}
                cancelButton={false}
                deleteButton={false}
                confirmButton={true}
                title={"Δημιουργία Δράσης"}
                message={alertMsg}
                redirectUrl={"actions"}
            />
        </>
    );
};

export default ActionForm;
