import React from "react";
import { Container, Row, Col } from "reactstrap";
import { PORTALMENU } from "../constant/menu";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
const Footer = () => (
    <>
        <footer className="resume copyright copyright-bg inner-pages-footer">
            <Container>
                <Row>
                    <Col xs="12" md="2">
                        <div className="link link-horizontal  text-center mb-3">
                            <img
                                alt=""
                                className="img-fluid"
                                src="assets/logo.png"
                            />
                        </div>
                    </Col>
                    <Col xs="12" md="3">
                        <div className="link link-vertical">
                            <ul className="justify-content-left">
                                {PORTALMENU.slice(0, 3).map((menuItem, i) => {
                                    return (
                                        <li key={i}>
                                            <HashLink
                                                to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                                                className={`copyright-text ${
                                                    menuItem.active
                                                        ? "active"
                                                        : ""
                                                }`}
                                            >
                                                <span>{menuItem.title}</span>
                                            </HashLink>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Col>
                    <Col xs="12" md="3">
                        <div className="link link-vertical">
                            <ul className="justify-content-left">
                                {PORTALMENU.slice(3, 6).map((menuItem, i) => {
                                    return (
                                        <li key={i}>
                                            <HashLink
                                                to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                                                className={`copyright-text ${
                                                    menuItem.active
                                                        ? "active"
                                                        : ""
                                                }`}
                                            >
                                                <span>{menuItem.title}</span>
                                            </HashLink>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Col>
                    <Col xs="12" md="3">
                        <div className="link link-vertical">
                            <ul className="justify-content-left">
                                {PORTALMENU.slice(6, 7).map((menuItem, i) => {
                                    return (
                                        <li key={i}>
                                            <HashLink
                                                to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                                                className={`copyright-text ${
                                                    menuItem.active
                                                        ? "active"
                                                        : ""
                                                }`}
                                            >
                                                <span>{menuItem.title}</span>
                                            </HashLink>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Col>
                    <Col xs="12" md="1">
                        <div className="text-center">
                            <div className="social-link link-horizontal">
                                <ul className="justify-content-center">
                                    <li>
                                        <a className="copyright-text" href="#">
                                            <i
                                                aria-hidden="true"
                                                className="fa fa-facebook"
                                            ></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="copyright-text" href="#">
                                            <i
                                                aria-hidden="true"
                                                className="fa fa-twitter"
                                            ></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="copyright-text " href="#">
                                            <i
                                                aria-hidden="true"
                                                className="fa fa-instagram"
                                            ></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="copyright-text " href="#">
                                            <i
                                                aria-hidden="true"
                                                className="fa fa-linkedin"
                                            ></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
        <div
            className="agency copyright inner-page"
            style={{ backgroundColor: "#3a5f88" }}
        >
            <Container>
                <Row>
                    <Col sm="6">
                        <div className="link-horizontal">
                            <ul>
                                {
                                    // <li><a className="copyright-text" href="#">Privacy Policy</a></li>
                                    // <li><a className="copyright-text" href="#">Terms &amp; Conditions</a></li>
                                }
                            </ul>
                        </div>
                    </Col>
                    <Col sm="6">
                        <div>
                            <h6 className="copyright-text text-white text-right">
                                {`Copyright © ${new Date().getFullYear()} Business in One`}
                            </h6>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </>
);


export default Footer;
