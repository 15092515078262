import React, { useEffect, useState, useContext } from "react";
import { Context as AuthContext } from "../context/authenticationProvider";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import FormModal from "./FormModal";
import ImageBox from "../components/ImageBox";
import ReactQuill from "react-quill";
import Delta from "quill-delta";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import api from "../api/api";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        margin: "30px 0 10px 7px",
    },
    buttonsFormControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        display: "inline-table",
    },
    submitButton: {
        color: "white",
        margin: 5,
        flex: 1,
        width: 150,
        float: "left",
        backgroundColor: "var(--primary)",
    },
    buttonsFormControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        display: "inline-table",
    },
    submitButton: {
        color: "white",
        margin: 5,
        flex: 1,
        width: 150,
        float: "right",
        backgroundColor: "var(--primary)",
    },
    deleteButton: {
        color: "white",
        margin: 5,
        flex: 1,
        width: 150,
        float: "left",
        backgroundColor: "var(--red)",
    },
}));

const BlogForm = () => {
    const classes = useStyles();
    const blogId = window.location.href.split("/").pop();
    const [state, login, logout] = useContext(AuthContext);
    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [content, setContent] = useState("");
    const [image, setImage] = useState("");
    const [imageToUpload, setImageToUpload] = useState();
    const [alertMsg, setAlertMsg] = useState("");
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const getBlog = async () => {
        try {
            const response = await api.get(`/EsBlogs/${blogId}`, {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                },
            });
            const blog = response.data;
            setTitle(blog.title);
            setDate(blog.date);
            setContent(blog.content);
            setImage(blog.image);
        } catch (err) {
            alert(err);
        }
    };

    useEffect(() => {
        getBlog();
    }, []);

    const submitForm = async () => {
        try {
            const formData = new FormData();
            formData.append("id", blogId);
            formData.append("title", title);
            formData.append("date", date);
            formData.append("content", content);
            if (image) {
                formData.append(
                    "image",
                    typeof image === "string" ? image : image[0]
                );
            }
            const response = await api.put(`/EsBlogs/${blogId}`, formData, {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                    "Content-Type": "multipart/form-data",
                },
            });
            setAlertMsg("Η επεξεργασία ολοκληρώθηκε με επιτυχία");
            setShowEditModal(true);
        } catch (err) {
            setAlertMsg(err);
            setShowEditModal(true);
        }
    };

    const deleteBlog = async () => {
        const response = await api.delete(`/EsBlogs/${blogId}`, {
            headers: {
                Authorization: "Bearer " + state.token.accessToken,
            },
        });
    };

    return (
        <>
            <h4 className={classes.header}>Στοιχεία Blog Post</h4>
            <div className="row">
                <div className="col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Τίτλος"
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Ημερομηνία"
                            type="date"
                            value={date}
                            onChange={(event) => setDate(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="col-12">
                    <FormControl className={classes.formControl}>
                        <ReactQuill
                            theme="snow"
                            onChange={setContent}
                            value={content}
                        />
                    </FormControl>
                </div>
                <div className="col-12">
                    <FormControl className={classes.formControl}>
                        <ImageBox
                            label={"Εικόνα"}
                            setImageState={(image) => setImage(image)}
                            image={image}
                        />
                    </FormControl>
                </div>
            </div>
            <div className="col-md-12 col-12">
                <FormControl className={classes.buttonsFormControl}>
                    <Button
                        color="primary"
                        className={classes.deleteButton}
                        onClick={() => {
                            setAlertMsg(
                                "Είστε σίγουροι ότι θέλετε να προχωρήσετε στη διαγραφή του Blog Post;"
                            );
                            setShowDeleteModal(true);
                        }}
                    >
                        Διαγραφη
                    </Button>
                    <Button
                        color="primary"
                        className={classes.submitButton}
                        onClick={() => submitForm()}
                    >
                        Αποθηκευση
                    </Button>
                </FormControl>
            </div>
            <FormModal
                showModal={showEditModal}
                setShowModal={setShowEditModal}
                cancelButton={false}
                deleteButton={false}
                confirmButton={true}
                title={"Επεξεργασία Blog Post"}
                message={alertMsg}
                redirectUrl={"blogs"}
            />
            <FormModal
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                cancelButton={true}
                deleteButton={true}
                confirmButton={false}
                title={"Διαγραφή Blog"}
                message={alertMsg}
                deleteItem={deleteBlog}
                redirectUrl={"blogs"}
            />
        </>
    );
};

export default BlogForm;
