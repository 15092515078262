import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const InnerNav = (props) => {
  const [mainmenu, setMainMenu] = useState(props.menu);
  const [sidebar, setSidebar] = useState(false);

  function closeSidebar() {
    setSidebar(!sidebar);
    document.querySelector(".navbar").classList.remove("openSidebar");
  }

  useEffect(() => {
    const currentUrl = window.location.pathname;
    mainmenu.filter((items) => {
      if (items.path === currentUrl) setNavActive(items);
      if (!items.children) return false;
      items.children.filter((subItems) => {
        if (subItems.path === currentUrl) setNavActive(subItems);
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === currentUrl) setNavActive(subSubItems);
        });
      });
    });
  }, []);

  const setNavActive = (item) => {
    props.menu.filter((menuItem) => {
      if (menuItem != item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: props.menu });
  };

  // Click Toggle menu
  const toggletNavActive = (item) => {
    if (!item.active) {
      props.menu.forEach((a) => {
        if (props.menu.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
          if (!b.children) return false;
          b.children.forEach((c) => {
            if (b.children.includes(item)) {
              c.active = false;
            }
          });
        });
      });
    }
    item.active = !item.active;
    setMainMenu({ mainmenu: props.menu });
  };

  return (
    <div className={`navbar`} id="togglebtn">
      <div className="responsive-btn">
        <a className="btn-back" onClick={closeSidebar}>
          <h5>back</h5>
        </a>
      </div>
      <ul className="main-menu">
        {props.menu.map((menuItem, i) => {
          return (
            <li key={i} className={` ${menuItem.megaMenu ? "mega-menu" : ""}`}>
              {menuItem.sidebartitle ? (
                <div className="dropdown">{menuItem.sidebartitle}</div>
              ) : (
                ""
              )}
              {menuItem.type === "sub" ? (
                <a
                  className="dropdown"
                  href="#javascript"
                  onClick={() => toggletNavActive(menuItem)}
                >
                  <span>{menuItem.title}</span>
                </a>
              ) : (
                ""
              )}
              {menuItem.type === "link" ? (
                <HashLink
                  to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                  className={`${menuItem.active ? "active" : ""}`}
                  onClick={() => toggletNavActive(menuItem)}
                >
                  <span>{menuItem.title}</span>
                  {menuItem.children ? (
                    <i className="fa fa-angle-right pull-right"></i>
                  ) : (
                    ""
                  )}
                </HashLink>
              ) : (
                ""
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default InnerNav;
