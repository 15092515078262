import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Container, Row, Col } from "reactstrap";
import { HashLink } from "react-router-hash-link";

const useStyles = makeStyles({
  root50: {
    width: "calc(50% - 120px)",
    margin: "60px",
    fontSize: 15,
    border: "1px solid #54cce4",
  },
  root100: {
    width: "calc(100% - 120px)",
    margin: "60px",
    fontSize: 15,
    border: "1px solid #54cce4",
    border: "1px solid #3a5f88"
  },
  actionTitle: {
    fontSize: 23,
    fontWeight: "500!important",
    padding: "25px 0",
    color: "#3a5f88!important",
  },
  description: {
    fontSize: "16px!important",
    lineHeight: "22px",
    fontWeight: "300!important",
    padding: "0px 55px 55px 0px",
  },
  icon50: {
    width: 75,
    height: 75,
    position: "absolute",
    marginLeft: "-25px",
    marginTop: "25px",
  },
  icon100: {
    width: 150,
    height: 150,
    position: "absolute",
    marginLeft: "-25px",
    marginTop: "25px",
  },
});

export default function ArticleBox2(props) {
  const action = props.action;
    const classes = useStyles();

    const imagePreFix = (props.url === 'blog') ? 'blog-image-' : 'action-main-image';
    const image = `https://resnovae.blob.core.windows.net/businessinone/${imagePreFix}${action.id}.png`;
  const MAX_LENGTH = props.isFullWidth ? 600 : 200;

  let description =
    props.url == "page" &&
    action.portalShortDescription.substring.length > MAX_LENGTH
      ? `${action.portalShortDescription.substring(0, MAX_LENGTH)} ...`
      : props.url == "page" &&
        action.portalShortDescription.substring.length <= MAX_LENGTH
      ? action.portalShortDescription
      : props.url == "blog" && action.content.length > MAX_LENGTH
      ? `${action.content.substring(0, MAX_LENGTH)} ...`
      : action.content;

  return (
    <React.Fragment>
      <div className={props.isFullWidth ? classes.root100 : classes.root50}>
        <HashLink to={`/${props.url}/${action.id}`}>
          <Row>
            <Col sm="2" className="">
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                image={image}
                className={props.isFullWidth ? classes.icon100 : classes.icon50}
                title="Contemplative Reptile"
              />
            </Col>
            <Col sm="10" className="">
                {action.isOpenForSubmision && (
                   <div className="available-for-submit" style={{margin: '-11px 0px'}}>ΑΝΟΙΧΤΗ ΓΙΑ ΥΠΟΒΟΛΕΣ</div>
                )}
              <h4 className={classes.actionTitle}>
                {props.url == "page" && action.portalTitle}
                {props.url == "blog" && action.title}
              </h4>
              <p
                className={classes.description}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              ></p>
            </Col>
          </Row>
        </HashLink>
      </div>
    </React.Fragment>
  );
}
