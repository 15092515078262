import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import "./assets/scss/landing.scss";
import HomeHeader from "./HomeHeader";
import BannerSection from "./Banner";
import Actions from "./Actions";
import ActiveActions from "./ActiveActions";
import QuickSearch from "./QuickSearch";
import CommunicationForm from "./CommunicationForm";
import Footer from "./common/footer";
import api from "../api/api";
import { Row } from "reactstrap";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import Slide from "./Slide";
import SlideBox from "./SlideBox";

import "react-awesome-slider/dist/styles.css";

const HomePage = () => {
  const [companyServices, setCompanyServices] = useState([]);
  const [companySizes, setCompanySizes] = useState([]);
  const [actions, setActions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [blogs, setBlogs] = useState(null);
  const [upcomingActions, setUpcomingActions] = useState(null);
  const [activeActions, setActiveActions] = useState(null);

  const getActions = async () => {
    try {
      const response = await api.get("/Slider");
      response.data.forEach((item, index) => {
        item.index = index + 1;
      });
      setActions(response.data);
    } catch (err) {
      alert(err);
    }
  };

  const getUpcomingActions = async () => {
    try {
      const response = await api.get("/Search?upcoming=True&limit=3");
      response.data.forEach((item, index) => {
        item.index = index + 1;
      });
      setUpcomingActions(response.data);
    } catch (err) {
      alert(err);
    }
  };

  const getActiveActions = async () => {
    try {
      const response = await api.get("/Search?active=True&limit=3");
      response.data.forEach((item, index) => {
        const today = new Date();
        const endSubmitDate = new Date(item.endSubmitDate);
        if (endSubmitDate > today) {
          item.isOpenForSubmision = true;
        }
        item.index = index + 1;
      });
      setActiveActions(response.data);
    } catch (err) {
      alert(err);
    }
  };

  const getBlogs = async () => {
    try {
      const response = await api.get("/EsBlogs");
      const blogs = response.data.sort((a, b) => b.id - a.id);
      setBlogs(blogs.slice(0, 3));
    } catch (err) {
      alert(err);
    }
  };

  const getCompanyServices = async () => {
    try {
      const response = await api.get("/EsCompanyServices");
      setCompanyServices(response.data);
    } catch (err) {
      alert(err);
    }
  };

  const getCompanySizes = async () => {
    try {
      const response = await api.get("/EsCompanySize");
      setCompanySizes(response.data);
    } catch (err) {
      alert(err);
    }
  };

  const getRegions = async () => {
    try {
      const response = await api.get("/EsRegions");
      setRegions(response.data);
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    getActions();
    getCompanyServices();
    getUpcomingActions();
    getActiveActions();
    getCompanySizes();
    getRegions();
    getBlogs();
  }, []);

  useEffect(() => {
    document.body.style.setProperty("--primary", "#357fef");
    document.body.style.setProperty("--secondary", "#4e4e4e");
    document.body.style.setProperty("--light", "#13B8EA");
    document.body.style.setProperty("--dark", "#4E56F3");
  });

  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelected((currCount) => (currCount < 3 ? currCount + 1 : 0));
    }, 7000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Business In One</title>
        <meta property="og:url" content="https://businessinone.gr/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="BusinessinOne" />
        <meta
          property="og:description"
          content="Portal Ενημέρωσης για όλα τα χρηματοδοτικά εργαλεία ΕΣΠΑ/ Αναπτυξιακού Νόμου κ.α."
        />
        <meta
          property="og:image"
          content="https://businessinone.gr/assets/logo-big.png"
        />
        <link rel="canonical" href="https://businessinone.gr/" />
      </Helmet>
      <HomeHeader />
      <AwesomeSlider
        bullets={false}
        selected={selected}
        organicArrows={false}
        style={{ "--slider-height-percentage": "640px" }}
      >
        <div className="slide-container left">
          <Slide action={actions[0]} position="left" />
        </div>
        <div className="slide-container right">
          <Slide action={actions[1]} position="right" />
        </div>
        <div className="slide-container left">
          <Slide action={actions[2]} position="left" />
        </div>
        <div className="slide-container right">
          <Slide action={actions[3]} position="right" />
        </div>
      </AwesomeSlider>
      <Row>
        <SlideBox
          action={actions[0]}
          index={0}
          selected={selected}
          setSelected={setSelected}
        />
        <SlideBox
          action={actions[1]}
          index={1}
          selected={selected}
          setSelected={setSelected}
        />
        <SlideBox
          action={actions[2]}
          index={2}
          selected={selected}
          setSelected={setSelected}
        />
        <SlideBox
          action={actions[3]}
          index={3}
          selected={selected}
          setSelected={setSelected}
        />
      </Row>
      <QuickSearch
        id="search"
        regions={regions}
        companySizes={companySizes}
        companyServices={companyServices}
      />
      <Actions
        id="draseis"
        headerColor="white"
        title="Ενεργές Δράσεις"
        className="active-actions-section"
        urlParams="active=True"
        url="page"
        actions={activeActions}
      />
      {/*      <ActiveActions
        id="draseis"
        title="Ενεργές Δράσεις"
        url="page"
        urlParams="active=True"
        actions={activeActions}
      />*/}
      <CommunicationForm
        id="communication"
        isCallForInfo={false}
        regions={regions}
        title={"Προσωποποιημένη Ενημέρωση"}
        subTitle={
          "Συμπληρώστε τα στοιχεία σας ώστε να λαμβάνετε στοχευμένη ενημέρωση σχετικά με χρηματοδοτικά εργαλεία που αφορούν την επιχείρησή σας."
        }
        companySizes={companySizes}
        companyServices={companyServices}
      />
      <Actions
        id="prosexeis"
        headerColor="white"
        title="Προσεχείς Δράσεις"
        className="upcoming-actions-section"
        urlParams="upcoming=True"
        url="page"
        headerColor="#3a5f88"
        actions={upcomingActions}
      />
      <Actions
        id="blog"
        url="blog"
        title="To Blog μας - Τελευταία Νέα"
        actions={blogs}
      />
      <Footer />
    </Fragment>
  );
};

export default HomePage;
