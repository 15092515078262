import React from "react";
import DashboardLayout from "./DashboardLayout";
import ClientActionForm from "./ClientActionForm";

export default function NewAction() {
    return (
        <DashboardLayout title={"Δράσεις"}>
            <ClientActionForm />
        </DashboardLayout>
    );
}

