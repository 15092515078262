import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet-async";
// import Custom Components
import InnerHeader from "./InnerHeader";
import InnerBanner from "./InnerBanner";
import DisplayFiles from "./DisplayFiles";
import InfoSectionRight from "./common/InfoSectionRight";
import InfoSectionLeft from "./common/InfoSectionLeft";
import ResNovaeInfo from "./common/ResNovaeInfo";
import Footer from "./common/footer";
import InnerAbout from "./InnerAbout";
import ActionTitle from "./ActionTitle";
import CommunicationForm from "./CommunicationForm";
import Button from "@material-ui/core/Button";
import api from "../api/api";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
    },
    button: {
        backgroundColor: "#4e4e4e",
        borderColor: "#4e4e4e",
        "&:hover": {
            backgroundColor: "#0069d9",
            borderColor: "#0062cc",
            boxShadow: "none",
        },
    },
}));

const InnerPage = () => {
    const actionId = window.location.href.split("/").pop();
    const [action, setAction] = useState(null);
    const [actionDetails, setActionDetails] = useState(null);
    const [companySizes, setCompanySizes] = useState([]);
    const [companyServices, setCompanyServices] = useState([]);
    const [showCommunicationForm, setShowCommunicationForm] = useState(false);
    const [regions, setRegions] = useState([]);
    const [section1, setSection1] = useState(null);
    const [section2, setSection2] = useState(null);
    const [section3, setSection3] = useState(null);
    const [section4, setSection4] = useState(null);
    const [section5, setSection5] = useState(null);
    const [section6, setSection6] = useState(null);
    const [scrollTop, setScrollTop] = useState(0);
    const [menu, setMenu] = useState([]);

    const [files, setFiles] = useState([]);

    const getAction = async (id) => {
        try {
            const response = await api.get(`/EsActions/${id}`);
            setAction(response.data);
            getActionDetails(response.data.id);
            window.scrollTo(0, 0);
        } catch (err) {
            alert(err);
        }
    };

    const getActionDetails = async (actionId) => {
        try {
            const response = await api.get(
                `/ActionDetailsByActionId/${actionId}`
            );
            const details = response.data;
            setSection1({
                header: details.section1Header,
                description: details.section1Description,
            });
            setSection2({
                header: details.section2Header,
                description: details.section2Description,
                image: details.section2Image,
            });
            setSection3({
                header: details.section3Header,
                description: details.section3Description,
                image: details.section3Image,
            });
            setSection4({
                header: details.section4Header,
                description: details.section4Description,
                image: details.section4Image,
            });
            setSection5({
                header: details.section5Header,
                description: details.section5Description,
                image: details.section5Image,
            });
            setSection6({
                header: details.section6Header,
                description: details.section6Description,
            });
            setActionDetails(response.data);
            const mainMenu = [
                {
                    title: "Αρχικη",
                    type: "link",
                    path: "/",
                },
                {
                    title: details.section1MenuName,
                    type: "link",
                    path: "#sectionone",
                },
                {
                    title: details.section2MenuName,
                    type: "link",
                    path: "#sectiontwo",
                },
                {
                    title: details.section3MenuName,
                    type: "link",
                    path: "#sectionthree",
                },
                {
                    title: details.section4MenuName,
                    type: "link",
                    path: "#sectionfour",
                },
                {
                    title: "Επικοινωνια",
                    type: "link",
                    path: "#form",
                },
            ];
            setMenu(mainMenu);
        } catch (err) {
            alert(err);
        }
    };

    const getCompanyServices = async () => {
        try {
            const response = await api.get("/EsCompanyServices");
            setCompanyServices(response.data);
        } catch (err) {
            alert(err);
        }
    };

    const getCompanySizes = async () => {
        try {
            const response = await api.get("/EsCompanySize");
            setCompanySizes(response.data);
        } catch (err) {
            alert(err);
        }
    };

    const getRegions = async () => {
        try {
            const response = await api.get("/EsRegions");
            setRegions(response.data);
        } catch (err) {
            alert(err);
        }
    };

    const getFiles = async () => {
        try {
            const response = await api.get(`/GetActionFiles/${actionId}`);
            setFiles(response.data);
        } catch (err) {
            alert(err);
        }
    };
    useEffect(() => {
        const onScroll = (e) => {
            setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    useEffect(() => {
        getAction(actionId);
        getCompanyServices();
        getCompanySizes();
        getRegions();
        getFiles();
    }, []);

    const metaTitle = (action) ? action.portalTitle : "";
    const metaDiscription = (action) ? action.portalShortDescription : "";

    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Business In One</title>
                <meta property="og:url" content="https://businessinone.gr/" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={metaTitle} />
                <meta
                    property="og:description"
                    content={metaDiscription}
                />
                <meta
                    property="og:image"
                    content="https://businessinone.gr/assets/logo-big.png"
                />
                <link rel="canonical" href="https://businessinone.gr/" />
            </Helmet>
            <InnerHeader className="app1" menu={menu} />
            {action && scrollTop > 450 && (
                <ActionTitle title={action.portalTitle} />
            )}
            <InnerBanner action={action} type={"action"} />
            {actionDetails && (
                <React.Fragment>
                    <div
                        style={{
                            backgroundColor: "#f5f5f8",
                            paddingTop: 60,
                            paddingBottom: 60,
                        }}
                    >
                        <Container>
                            <InnerAbout details={section1} id="sectionone" />
                            {section2.image ? (
                                <InfoSectionRight
                                    details={section2}
                                    id="sectiontwo"
                                />
                            ) : (
                                <InnerAbout
                                    details={section2}
                                    id="sectiontwo"
                                />
                            )}
                            {section3.image ? (
                                <InfoSectionLeft
                                    details={section3}
                                    id="sectionthree"
                                />
                            ) : (
                                <InnerAbout
                                    details={section3}
                                    id="sectionthree"
                                />
                            )}
                            {section4.image ? (
                                <InfoSectionRight
                                    details={section4}
                                    id="sectionfour"
                                />
                            ) : (
                                <InnerAbout
                                    details={section4}
                                    id="sectionfour"
                                />
                            )}
                            {section5.image ? (
                                <InfoSectionLeft
                                    details={section5}
                                    id="sectionfive"
                                />
                            ) : (
                                <InnerAbout
                                    details={section5}
                                    id="sectionfive"
                                />
                            )}
                            <InnerAbout details={section6} id="sectionsix" />
                            {files && (
                                <DisplayFiles id="sectionseven" files={files} />
                            )}
                        </Container>
                        <section id="form"></section>

                        {!showCommunicationForm && (
                            <section style={{ textAlign: "center" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        setShowCommunicationForm(true)
                                    }
                                >
                                    Θελω να ενημερωθω
                                </Button>
                            </section>
                        )}
                    </div>
                    {showCommunicationForm && (
                        <CommunicationForm
                            regions={regions}
                            isCallForInfo={true}
                            subject={action.portalTitle}
                            title={"Φόρμα Επικοινωνίας"}
                            subTitle={
                                "Συμπληρώστε τα στοιχεία και σύντομα ένας εκπρόσωπός μας θα επικοινωνήσει μαζί σας."
                            }
                            companySizes={companySizes}
                            companyServices={companyServices}
                        />
                    )}
                    <ResNovaeInfo />
                </React.Fragment>
            )}
            <Footer />
        </React.Fragment>
    );
};

export default InnerPage;
