import React from "react";
import DashboardLayout from "./DashboardLayout";
import ClientsList from "./ClientsList";

export default function NewAction() {
  return (
    <DashboardLayout title={"Πελάτες"}>
      <ClientsList />
    </DashboardLayout>
  );
}
