import React, { useEffect, useState, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import ImageBox from "../../components/ImageBox";
import ReactQuill from "react-quill";
import Delta from "quill-delta";
import Button from "@material-ui/core/Button";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: "100%",
	},
	imageBox: {
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(3),
		minWidth: "100%",
	},
	checkBox: {
		padding: theme.spacing(1),
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	index: {
		margin: theme.spacing(1),
		width: 50,
	},
	fileDetails: {
		margin: theme.spacing(1),
		width: 200,
	},
	header: {
		margin: "30px 0 10px 7px",
	},
	buttonsFormControl: {
		margin: theme.spacing(1),
		minWidth: "100%",
		display: "inline-table",
	},
	submitButton: {
		color: "white",
		margin: 5,
		flex: 1,
		width: 150,
		float: "left",
		backgroundColor: "var(--primary)",
	},
}));

const ActionFormDetails = (props) => {
	const classes = useStyles();
	const fields = [
		{
			name: "section1Header",
			label: "Τίτλoς για το Section 1",
			value: "",
			type: "text",
			autoFormData: true,
		},
		{
			name: "section1Description",
			label: "Περιγραφή για το Section 1",
			value: "",
			type: "editor",
			autoFormData: true,
		},
		{
			name: "section1MenuName",
			label: "Μενού για το Section 1",
			value: "",
			type: "text",
			autoFormData: true,
		},
		{
			name: "section2Header",
			label: "Τίτλoς για το Section 2",
			value: "",
			type: "text",
			autoFormData: true,
		},
		{
			name: "section2Description",
			label: "Περιγραφή για το Section 2",
			value: "",
			type: "editor",
			autoFormData: true,
		},
		{
			name: "section2MenuName",
			label: "Μενού για το Section 2",
			value: "",
			type: "text",
			autoFormData: true,
		},
		{
			name: "section2Image",
			label: "Εικόνα για το Section 2",
			value: "",
			type: "image",
		},
		{
			name: "section3Header",
			label: "Τίτλoς για το Section 3",
			value: "",
			type: "text",
			autoFormData: true,
		},
		{
			name: "section3Description",
			label: "Περιγραφή για το Section 3",
			value: "",
			type: "editor",
			autoFormData: true,
		},
		{
			name: "section3MenuName",
			label: "Μενού για το Section 3",
			value: "",
			type: "text",
			autoFormData: true,
		},
		{
			name: "section3Image",
			label: "Εικόνα για το Section 3",
			value: "",
			type: "image",
		},
		{
			name: "section4Header",
			label: "Τίτλoς για το Section 4",
			value: "",
			type: "text",
			autoFormData: true,
		},
		{
			name: "section4Description",
			label: "Περιγραφή για το Section 4",
			value: "",
			type: "editor",
			autoFormData: true,
		},
		{
			name: "section4MenuName",
			label: "Μενού για το Section 4",
			value: "",
			type: "text",
			autoFormData: true,
		},
		{
			name: "section4Image",
			label: "Εικόνα για το Section 4",
			value: "",
			type: "image",
		},
		{
			name: "section5Header",
			label: "Τίτλoς για το Section 5",
			value: "",
			type: "text",
			autoFormData: true,
		},
		{
			name: "section5Description",
			label: "Περιγραφή για το Section 5",
			value: "",
			type: "editor",
			autoFormData: true,
		},
		{
			name: "section5MenuName",
			label: "Μενού για το Section 5",
			value: "",
			type: "text",
			autoFormData: true,
		},
		{
			name: "section5Image",
			label: "Εικόνα για το Section 5",
			value: "",
			type: "image",
		},
		{
			name: "section6Header",
			label: "Τίτλoς για το Section 6",
			value: "",
			type: "text",
			autoFormData: true,
		},
		{
			name: "section6Description",
			label: "Περιγραφή για το Section 6",
			value: "",
			type: "editor",
			autoFormData: true,
		},
		{
			name: "section6MenuName",
			label: "Μενού για το Section 6",
			value: "",
			type: "text",
			autoFormData: true,
		},
	];

	const [state, setState] = useState(fields);

	useEffect(() => {
		if (props.actionDetails) {
			const newState = [...state];
			console.log(props.actionDetails);
			newState.forEach((field) => {
				field.value = props.actionDetails[field.name];
			});
			// newState.forEach((field) => {
			// 	field.value =
			// 		field.type === "editor"
			// 			? new DOMParser().parseFromString(
			// 					props.actionDetails[field.name],
			// 					"text/xml"
			// 			  )
			// 			: props.actionDetails[field.name];
			// });
			setState(newState);
			props.setDetailsState(newState);
		}
	}, [props.actionDetails]);

	const updateValue = (name, value) => {
		const newState = [...state];
		const field = newState.find((item) => item.name === name);
		field.value = value;
		setState(newState);
		props.setDetailsState(newState);
	};

	const [value, setValue] = useState("");
	return (
		<React.Fragment>
			{state.map((field, index) =>
				field.type === "text" ? (
					<div className="col-12" key={index}>
						<FormControl className={classes.formControl}>
							<TextField
								label={field.label}
								value={field.value}
								multiline
								maxRows={20}
								onChange={(event) =>
									updateValue(field.name, event.target.value)
								}
							/>
						</FormControl>
					</div>
				) : field.type === "editor" ? (
					<div className="col-12" key={index}>
						<FormControl className={classes.formControl}>
								<ReactQuill
									theme="snow"
									onChange={(
										content,
										delta,
										source,
										editor
									) => {
										updateValue(field.name, content);
									}}
									value={field.value}
								/>
						</FormControl>
					</div>
				) : field.type === "image" ? (
					<div className={classes.imageBox} key={index}>
						<ImageBox
							label={field.label}
							setImageState={(image) =>
								updateValue(field.name, image)
							}
							image={field.value}
						/>
					</div>
				) : (
					<div className="col-12" key={index}>
						<FormControlLabel
							control={
								<Checkbox
									onChange={(event) =>
										updateValue(
											field.name,
											event.target.checked
										)
									}
									checked={field.value}
									name="checkedB"
									color="primary"
								/>
							}
							label={field.label}
							className={classes.checkBox}
						/>
					</div>
				)
			)}
			<div className="col-md-6 col-12">
				<FormControl className={classes.buttonsFormControl}>
					<Button
						className={classes.submitButton}
						variant="outlined"
						color="primary"
						onClick={() => props.putActionDetails()}
					>
						ΕΠΟΜΕΝΟ
					</Button>
				</FormControl>
			</div>
		</React.Fragment>
	);
};
export default ActionFormDetails;
