import React, { useEffect, useState, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import FormModal from "./FormModal";
import api from "../api/api";
import { Context as AuthContext } from "../context/authenticationProvider";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        margin: "30px 0 10px 7px",
    },
    modal: {
        display: "flex",
        padding: theme.spacing(1),
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    buttonsFormControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        display: "inline-table",
    },
    submitButton: {
        color: "white",
        margin: 5,
        flex: 1,
        width: 150,
        float: "right",
        backgroundColor: "var(--primary)",
    },
    deleteButton: {
        color: "white",
        margin: 5,
        flex: 1,
        width: 150,
        float: "left",
        backgroundColor: "var(--red)",
    },
}));

const initialFieldValidation = {
    name: false,
    email: false,
    address: false,
    contactPerson: false,
    phone: false,
    vat: false,
    city: false,
    zipCode: false,
    kad: false,
    companyServices: false,
    companySize: false,
    region: false,
};

const kadList = [
    {
        label: "00 Έλλειψη δραστηριότητας",
    },
    {
        label:
            "00.01 Έλλειψη δραστηριότητας, λόγω μη δραστηριοποίησης μέχρι σήμερα",
    },
    {
        label:
            "00.03 Έλλειψη δραστηριότητας, λόγω δραστηριοποίησης μόνο εκτός Ελλάδας",
    },
    {
        label: "01.1 Καλλιέργεια μη πολυετών φυτών",
    },
];

const EditClientForm = () => {
    const clientId = window.location.href.split("/").pop();
    const [state, login, logout] = useContext(AuthContext);
    const classes = useStyles();

    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [phone2, setPhone2] = useState("");
    const [vat, setVat] = useState("");
    const [kad, setKad] = useState("");
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [contactPersonMobilePhone, setContactPersonMobilePhone] = useState(
        ""
    );
    const [contactPersonEmail, setContactPersonEmail] = useState("");
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [website, setWebSite] = useState("");
    const [companyServicesId, setCompanyServicesId] = useState([0]);
    const [companySizeId, setCompanySizeId] = useState([0]);
    const [regionId, setRegionId] = useState([0]);
    const [fieldValidation, setFieldValidation] = useState(
        initialFieldValidation
    );

    const [companyServices, setCompanyServices] = useState([]);
    const [companySizes, setCompanySizes] = useState([]);
    const [regions, setRegions] = useState([]);

    const getCompanyServices = async () => {
        try {
            const response = await api.get("/EsCompanyServices", {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                },
            });
            setCompanyServices(response.data);
        } catch (err) {
            alert(err);
        }
    };

    const getCompanySizes = async () => {
        try {
            const response = await api.get("/EsCompanySize", {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                },
            });
            setCompanySizes(response.data);
        } catch (err) {
            alert(err);
        }
    };

    const getRegions = async () => {
        try {
            const response = await api.get("/EsRegions", {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                },
            });
            setRegions(response.data);
        } catch (err) {
            alert(err);
        }
    };

    const getClient = async () => {
        try {
            const response = await api.get(`/EsClients/${clientId}`, {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                },
            });
            const client = response.data;
            setName(client.name);
            setEmail(client.email);
            setPhone(client.phone);
            setPhone2(client.phone2);
            setVat(client.vat);
            setAddress(client.address);
            setContactPerson(client.contactPerson);
            setContactPersonMobilePhone(client.contactPersonMobilePhone);
            setContactPersonEmail(client.contactPersonEmail);
            setKad(client.kad);
            setCity(client.city);
            setZipCode(client.zipCode);
            setWebSite(client.website);
            setCompanySizeId(client.companySizeId.split(",").map(Number));
            setRegionId(client.regionId.split(",").map(Number));
            setCompanyServicesId(
                client.companyServicesId.split(",").map(Number)
            );
        } catch (err) {
            alert(err);
        }
    };

    useEffect(() => {
        getCompanyServices();
        getCompanySizes();
        getRegions();
        getClient();
    }, []);

    const submitForm = async () => {
        const currentFieldValidation = {
            name: !name,
            email: !email,
            address: !address,
            contactPerson: !contactPerson,
            city: !city,
            zipCode: !zipCode,
            phone: !phone,
            vat: !vat,
            kad: !kad,
            companyServices: !companyServicesId,
            companySize: !companySizeId,
            region: !regionId,
        };
        setFieldValidation(currentFieldValidation);
        const fieldValidationKeys = Object.keys(currentFieldValidation);
        let isFormValid = true;
        fieldValidationKeys.forEach((key) => {
            if (currentFieldValidation[key]) {
                isFormValid = false;
            }
        });
        if (!isFormValid) {
            return;
        }
        try {
            const data = {
                id: clientId,
                name,
                email,
                phone,
                phone2,
                contactPerson,
                contactPersonMobilePhone,
                contactPersonEmail,
                city,
                zipCode,
                website,
                vat: Number(vat),
                kad,
                companySizeId: companySizeId.toString(),
                companyServicesId: companyServicesId.toString(),
                regionId: regionId.toString(),
                address,
            };
            const response = await api.put(`/EsClients/${clientId}`, data, {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                },
            });
            setAlertMsg("Η επεξεργασία ολοκληρώθηκε με επιτυχία");
            setShowEditModal(true);
        } catch (err) {
            setAlertMsg(err);
            setShowEditModal(true);
        }
    };

    const deleteClient = async () => {
        const response = await api.delete(`/EsClients/${clientId}`, {
            headers: {
                Authorization: "Bearer " + state.token.accessToken,
            },
        });
    };

    return (
        <>
            <h4 className={classes.header}>Στοιχεία Πελάτη</h4>
            <div className="row">
                <div className="col-md-4 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            error={fieldValidation.name}
                            label="Επωνυμία Επιχείρησης / Ονοματεπώνυμο"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="col-md-4 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            error={fieldValidation.contactPerson}
                            value={contactPerson}
                            onChange={(event) =>
                                setContactPerson(event.target.value)
                            }
                            label="Contact Person"
                        />
                    </FormControl>
                </div>
                <div className="col-md-4 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            value={contactPersonMobilePhone}
                            onChange={(event) =>
                                setContactPersonMobilePhone(event.target.value)
                            }
                            label="Contact Person Mobile Phone"
                        />
                    </FormControl>
                </div>
                <div className="col-md-4 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            value={contactPersonEmail}
                            onChange={(event) =>
                                setContactPersonEmail(event.target.value)
                            }
                            label="Contact Person E-mail"
                        />
                    </FormControl>
                </div>
                <div className="col-md-4 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="E-mail"
                            error={fieldValidation.email}
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="col-md-4 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Πόλη"
                            error={fieldValidation.city}
                            value={city}
                            onChange={(event) => setCity(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="col-md-4 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Διεύθυνση"
                            error={fieldValidation.address}
                            value={address}
                            onChange={(event) => setAddress(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="col-md-4 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="T.K."
                            error={fieldValidation.zipCode}
                            value={zipCode}
                            onChange={(event) => setZipCode(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="col-md-4 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Tηλέφωνο"
                            value={phone}
                            error={fieldValidation.phone}
                            onChange={(event) => setPhone(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="col-md-4 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Tηλέφωνο 2"
                            value={phone2}
                            error={fieldValidation.phone}
                            onChange={(event) => setPhone2(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="col-md-4 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="website"
                            value={website}
                            onChange={(event) => setWebSite(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="col-md-4 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="ΑΦΜ"
                            value={vat}
                            error={fieldValidation.vat}
                            onChange={(event) => setVat(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="col-md-4 col-12">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            error={fieldValidation.kad}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={{ label: kad }}
                            options={kadList}
                            onChange={(event, value) => {
                                value && setKad(value.label);
                            }}
                            getOptionLabel={(kad) => kad.label}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="ΚΑΔ"
                                    error={fieldValidation.kad}
                                    defaultValue={kad}
                                />
                            )}
                        ></Autocomplete>
                    </FormControl>
                </div>
                <div className="col-md-4 col-12">
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-helper-label">
                            Μέγεθος Επιχείρησης
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={companySizeId}
                            error={fieldValidation.companySize}
                            onChange={(event) =>
                                setCompanySizeId(event.target.value)
                            }
                        >
                            {companySizes.map((size, index) => (
                                <MenuItem key={index} value={size.id}>
                                    {size.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-4 col-12">
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-helper-label">
                            Αντικείμενο Επιχείρησης
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            multiple
                            error={fieldValidation.companyServices}
                            value={companyServicesId}
                            onChange={(event) =>
                                setCompanyServicesId(event.target.value)
                            }
                        >
                            {companyServices.map((service, index) => (
                                <MenuItem key={index} value={service.id}>
                                    {service.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-4 col-12">
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-helper-label">
                            Περιφέρεια
                        </InputLabel>
                        <Select
                            value={regionId}
                            error={fieldValidation.region}
                            multiple
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            onChange={(event) =>
                                setRegionId(event.target.value)
                            }
                        >
                            {regions.map((region, index) => (
                                <MenuItem key={index} value={region.id}>
                                    {region.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className="col-md-12 col-12">
                <FormControl className={classes.buttonsFormControl}>
                    <Button
                        color="primary"
                        className={classes.deleteButton}
                        onClick={() => {
                            setAlertMsg(
                                "Είστε σίγουροι ότι θέλετε να προχωρήσετε στη διαγραφή του Πελάτη;"
                            );
                            setShowDeleteModal(true);
                        }}
                    >
                        Διαγραφη
                    </Button>
                    <Button
                        color="primary"
                        className={classes.submitButton}
                        onClick={() => submitForm()}
                    >
                        Αποθηκευση
                    </Button>
                </FormControl>
            </div>
            <FormModal
                showModal={showEditModal}
                setShowModal={setShowEditModal}
                cancelButton={false}
                deleteButton={false}
                confirmButton={true}
                title={"Επεξεργασία Πελάτη"}
                message={alertMsg}
                redirectUrl={"clients"}
            />
            <FormModal
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                cancelButton={true}
                deleteButton={true}
                confirmButton={false}
                title={"Διαγραφή Πελάτη"}
                message={alertMsg}
                deleteItem={deleteClient}
                redirectUrl={"clients"}
            />
        </>
    );
};

export default EditClientForm;
