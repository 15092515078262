import React from "react";
import { Container, Row, Col } from "reactstrap";
const InfoSectionLeft = (props) => {
    return (
        props.details && (
            <Container id={props.id}>
                <Row className={"inner-page-sections-row"}>
                    <Col sm={6}>
                        <h4>{props.details.header}</h4>
                        <div className="body-text">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: props.details.description,
                                }}
                            ></p>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <img
                            className="fade-in details-image"
                            src={`data:image/png;base64,${props.details.image}`}
                            data-src={`data:image/png;base64,${props.details.image}`}
                            alt=""
                        />
                    </Col>
                </Row>
            </Container>
        )
    );
};

export default InfoSectionLeft;
