import React, { useEffect, useState, useContext } from "react";
import { Context as AuthContext } from "../context/authenticationProvider";
import { DataGrid } from '@mui/x-data-grid';
import DataGridToolbar from "./DataGridToolbar";
import { useHistory } from "react-router-dom";
import api from "../api/api";

export default function ClientActionsList() {
    const [state, login, logout] = useContext(AuthContext);
    const [clients, setClients] = useState([]);
    const [actions, setActions] = useState([]);
    const [clientActions, setClientActions] = useState([]);
    const [listItems, setListItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const history = useHistory();

    const getActions = async () => {
        try {
            const response = await api.get("/EsActions", {
                    headers: {
                        Authorization: "Bearer " + state.token.accessToken,
                    },
                });
            setActions(response.data);
        } catch (err) {
            alert(err);
        }
    };

    const getClients = async () => {
        try {
            const response = await api.get("/EsClients", {
                    headers: {
                        Authorization: "Bearer " + state.token.accessToken,
                    },
                });
            setClients(response.data);
        } catch (err) {
            alert(err);
        }
    };

    const getClientActions = async () => {
        try {
            const response = await api.get("/ClientActionsList", {
                    headers: {
                        Authorization: "Bearer " + state.token.accessToken,
                    },
                });
            setClientActions(response.data);
            setLoading(false);
        } catch (err) {
            alert(err);
            setLoading(false);
        }
    };


    useEffect(() => {
        getClientActions();
    }, []);

    const goToEdit = (id) => {
        history.push(`/edit-client-action/${id}`);
    };

    const columns = [
        // { field: "index", headerName: "A/A", width: 100 },
        { field: "name", headerName: "Όνομα Πελάτη", flex: 1},
        { field: "description", headerName: "Τίτλος", flex: 1 },
        { field: "authority", headerName: "Φορέας", flex: 1 },
        {
            field: "program",
            headerName: "Πρόγραμμα",
            flex: 1,
        },
        { field: "status", headerName: "Κατάσταση", flex: 1 },
    ];

    return (
        <React.Fragment>
            <div style={{ height: 530, width: "100%", cursor: "pointer" }}>
                <DataGrid
                    // sortModel={[
                    //     {
                    //         field: "name",
                    //         sort: "asc",
                    //     },
                    // ]}
                    components={{
                      Toolbar: DataGridToolbar,
                    }}
                    rows={clientActions}
                    columns={columns}
                    pageSize={20}
                    onRowClick={(event) => goToEdit(event.id)}
                    // checkboxSelection
                    loading={loading}
                />
            </div>
        </React.Fragment>
    );
}
