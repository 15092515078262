import React, { useEffect, useState } from "react";
import { PORTALMENU } from "./constant/menu";
import { Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Nav = () => {
    const [mainmenu, setMainMenu] = useState(PORTALMENU);
    const [sidebar, setSidebar] = useState(false);

    function closeSidebar() {
        setSidebar(!sidebar);
        document.querySelector(".navbar").classList.remove("openSidebar");
    }

    useEffect(() => {
        const currentUrl = window.location.pathname;
        mainmenu.filter((items) => {
            if (items.path === currentUrl) setNavActive(items);
            if (!items.children) return false;
            items.children.filter((subItems) => {
                if (subItems.path === currentUrl) setNavActive(subItems);
                if (!subItems.children) return false;
                subItems.children.filter((subSubItems) => {
                    if (subSubItems.path === currentUrl)
                        setNavActive(subSubItems);
                });
            });
        });
    }, []);

    const setNavActive = (item) => {
        PORTALMENU.filter((menuItem) => {
            if (menuItem != item) menuItem.active = false;
            if (menuItem.children && menuItem.children.includes(item))
                menuItem.active = true;
            if (menuItem.children) {
                menuItem.children.filter((submenuItems) => {
                    if (
                        submenuItems.children &&
                        submenuItems.children.includes(item)
                    ) {
                        menuItem.active = true;
                        submenuItems.active = true;
                    }
                });
            }
        });
        item.active = !item.active;
        setMainMenu({ mainmenu: PORTALMENU });
    };

    // Click Toggle menu
    const toggletNavActive = (item) => {
        if (!item.active) {
            PORTALMENU.forEach((a) => {
                if (PORTALMENU.includes(item)) a.active = false;
                if (!a.children) return false;
                a.children.forEach((b) => {
                    if (a.children.includes(item)) {
                        b.active = false;
                    }
                    if (!b.children) return false;
                    b.children.forEach((c) => {
                        if (b.children.includes(item)) {
                            c.active = false;
                        }
                    });
                });
            });
        }
        item.active = !item.active;
        setMainMenu({ mainmenu: PORTALMENU });
    };

    return (
        <div className={`navbar`} id="togglebtn">
            <div className="responsive-btn">
                <a className="btn-back" onClick={closeSidebar}>
                    <h5>back</h5>
                </a>
            </div>
            <ul className="main-menu">
                {PORTALMENU.map((menuItem, i) => {
                    return (
                        <li key={i}>
                            <HashLink
                                to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                                target={menuItem.target}
                                className={`${menuItem.active ? "active" : ""}`}
                                onClick={() => toggletNavActive(menuItem)}
                            >
                                <span>{menuItem.title}</span>
                            </HashLink>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Nav;
