import React, { useEffect, useState, useContext } from "react";
import { Context as AuthContext } from "../../context/authenticationProvider";
import DashboardLayout from "../DashboardLayout";
import ActionForm from "./ActionForm";
import api from "../../api/api";

export default function NewAction() {
	const actionId = window.location.href.split("/").pop();
	const [action, setAction] = useState();
	const [actionDetails, setActionDetails] = useState();
	const [state, login, logout] = useContext(AuthContext);
	const [loadingMessage, setLoadingMessage] = React.useState("Loading");
	const [loading, setLoading] = React.useState(false);

	const getAction = async () => {
		setLoadingMessage('Λήψη στοιχείων Δράσης');
		setLoading(true);
		try {
			const response = await api.get(`/EsActions/${actionId}`, {
				headers: {
					Authorization: "Bearer " + state.token.accessToken,
				},
			});
			setAction(response.data);
		} catch (err) {
			alert(err);
		}
		setLoading(false);
	};
	const getActionDetails = async () => {
		setLoadingMessage('Λήψη στοιχείων Δράσης');
		setLoading(true);
		try {
			const response = await api.get(
				`/ActionDetailsByActionId/${actionId}`,
				{
					headers: {
						Authorization: "Bearer " + state.token.accessToken,
					},
				}
			);
			const details = response.data;
			setActionDetails(details);
		} catch (err) {
			alert(err);
		}
		setLoading(false);
	};
	useEffect(() => {
		getAction();
		getActionDetails();
	}, []);
	return (
		<DashboardLayout
			title={"Επεξεργασία Δράσης"}
			loading={loading}
			loadingMessage={loadingMessage}
		>
			{action && actionDetails && actionDetails && (
				<ActionForm
					action={action}
					actionDetails={actionDetails}
					setLoadingMessage={setLoadingMessage}
					setLoading={setLoading}
				/>
			)}
		</DashboardLayout>
	);
}
