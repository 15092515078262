import React from "react";
import Tilt from "react-parallax-tilt";
import { Container, Row, Col } from "reactstrap";
import { HashLink } from "react-router-hash-link";

const Slide = (props) => {
    const action = props.action;
    const MAX_LENGTH = 200;

    if (!action) {
        return <></>;
    }

    return (
        <section
            className="app1 header slide home full-height-relative-position"
            id="home"
        >
            <div
                className="app1-header bg full-height-relative-position"
                style={{
                    backgroundImage: `url(https://resnovae.blob.core.windows.net/businessinone/action-main-image${action.id}.png)`,
                    backgroundPosition: "center",
                }}
            >
                <div className="header-slide-overlay"></div>
                <Row>
                    <Col
                        lg="8"
                        md="8"
                        className={props.position === "right" ? "offset-4" : ""}
                    >
                        <div className="center-text full-height-relative-position">
                            <div className="slider-text-container">
                                <div className="header-text">
                                    <HashLink to={`/page/${action.id}`}>
                                        <h1 className="header-title slide-link">
                                            {action.portalTitle}
                                        </h1>
                                    </HashLink>
                                </div>
                                {action.portalShortDescription &&
                                    <div className="header-sub-text header-link">
                                        <HashLink to={`/page/${action.id}`}>
                                            <p className="text-white slide-link font-size-22">{`${action.portalShortDescription.substring(
                                                0,
                                                MAX_LENGTH
                                            )} .....`}</p>
                                        </HashLink>
                                    </div>
                                }
                                <div className="header-sub-text header-link">
                                    <HashLink to={`/page/${action.id}`}>
                                        <p className="text-white slide-link font-size-18">
                                            Μάθετε περισσότερα
                                        </p>
                                    </HashLink>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default Slide;
