import React, { useEffect, useState } from "react";
import Footer from "./common/footer";
import HomeHeader from "./HomeHeader";
import SearchActions from "./SearchActions";
import ResNovaeInfo from "./common/ResNovaeInfo";
import CommunicationForm from "./CommunicationForm";
import api from "../api/api";

const BlogsPage = () => {
	const url = new URL(window.location.href);
	const params = url.search;
	const [companySizes, setCompanySizes] = useState([]);
	const [companyServices, setCompanyServices] = useState([]);
	const [regions, setRegions] = useState([]);
	const [blogs, setBlogs] = useState([]);

	const getCompanyServices = async () => {
		try {
			const response = await api.get("/EsCompanyServices");
			setCompanyServices(response.data);
		} catch (err) {
			alert(err);
		}
	};

	const getCompanySizes = async () => {
		try {
			const response = await api.get("/EsCompanySize");
			setCompanySizes(response.data);
		} catch (err) {
			alert(err);
		}
	};

	const getRegions = async () => {
		try {
			const response = await api.get("/EsRegions");
			setRegions(response.data);
		} catch (err) {
			alert(err);
		}
	};

	const getBlogs = async () => {
		try {
			const response = await api.get("/EsBlogs");
			const blogs = response.data.sort((a, b) => b.id - a.id);
			blogs.forEach((item, index) => {
				item.index = index + 1;
				item.description = item.content;
			});
			setBlogs(blogs);
			window.scrollTo(0, 0);
		} catch (err) {
			alert(err);
		}
	};

	useEffect(() => {
		getBlogs();
		getCompanyServices();
		getCompanySizes();
		getRegions();
	}, []);

	return (
		<React.Fragment>
			<HomeHeader className="app1" />
			<SearchActions
				id="draseis"
				title="Τελευταία Νέα - Blog"
				className="active-actions-section results"
				actions={blogs}
				url="blog"
			/>
			<ResNovaeInfo />
			<CommunicationForm
				id="form"
				regions={regions}
				title={"Φόρμα Επικοινωνίας"}
				subTitle={
					"Συμπληρώστε τα στοιχεία και σύντομα ένας εκπρόσωπός μας θα επικοινωνήσει μαζί σας."
				}
				companySizes={companySizes}
				companyServices={companyServices}
			/>
			<Footer />
		</React.Fragment>
	);
};

export default BlogsPage;
