import React from "react";
import DashboardLayout from "./DashboardLayout";
import ActionsList from "./ActionsList";

export default function NewAction() {
  return (
    <DashboardLayout title={"Δράσεις"}>
      <ActionsList />
    </DashboardLayout>
  );
}
