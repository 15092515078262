import React from "react";
import DashboardLayout from "./DashboardLayout";
import BlogForm from "./BlogForm";

export default function NewAction() {
  return (
    <DashboardLayout title={"Νέο Blog Post"}>
      <BlogForm />
    </DashboardLayout>
  );
}
