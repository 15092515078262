import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { HashLink } from "react-router-hash-link";

const useStyles = makeStyles({
  root: {
    width: 345,
    margin: 11,
  },
  actionTitle: {
    fontSize: 20,
    fontWeight: "500!important",
    color: "#3a5f88!important",
  },
  description: {
    fontSize: "16px!important",
    lineHeight: "22px",
    fontWeight: "300!important",
    padding: "20px 0px 0px 0px",
  },
});

export default function BlogBox(props) {
  const action = props.action;
  const classes = useStyles();
    const image = `https://resnovae.blob.core.windows.net/businessinone/blog-image-${action.id}.png`;
  const MAX_LENGTH = 200;
  let description =
    props.url == "page" &&
    action.portalShortDescription.substring.length > MAX_LENGTH
      ? `${action.portalShortDescription.substring(0, MAX_LENGTH)} ...`
      : props.url == "page" &&
        action.portalShortDescription.substring.length <= MAX_LENGTH
      ? action.portalShortDescription
      : props.url == "blog" && action.content.length > MAX_LENGTH
      ? `${action.content.substring(0, MAX_LENGTH)} ...`
      : action.content;

  return (
    <Card className={classes.root}>
      <HashLink to={`/${props.url}/${action.id}`}>
        <CardActionArea>
          {action.isOpenForSubmision && (
            <div className="available-for-submit">ΑΝΟΙΧΤΗ ΓΙΑ ΥΠΟΒΟΛΕΣ</div>
          )}
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="240"
            image={image}
            title="Contemplative Reptile"
          />
          <CardContent>
            <h4 className={classes.actionTitle}>
              {props.url == "page" && action.portalTitle}
              {props.url == "blog" && action.title}
            </h4>
            <p
              className={classes.description}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            ></p>
          </CardContent>
        </CardActionArea>
      </HashLink>
    </Card>
  );
}
