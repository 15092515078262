import React, { useEffect, useState, useContext } from "react";
import { Context as AuthContext } from "../context/authenticationProvider";
import { DataGrid } from "@material-ui/data-grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import api from "../api/api";
import { makeStyles } from "@material-ui/core/styles";
import Dropzone from "react-dropzone-uploader";
import Modal from "@material-ui/core/Modal";
import "react-dropzone-uploader/dist/styles.css";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import ActionDetailsForm from "./action/ActionDetailsForm";
import ImageBox from "../components/ImageBox";
import FormModal from "./FormModal";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    index: {
        margin: theme.spacing(1),
        width: 50,
    },
    fileDetails: {
        margin: theme.spacing(1),
        width: 200,
    },
    header: {
        margin: "30px 0 5px 20px",
    },
    modal: {
        display: "flex",
        padding: theme.spacing(1),
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    buttonsFormControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        display: "inline-table",
    },
    imageBox: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        minWidth: "100%",
    },
    submitButton: {
        color: "white",
        margin: 5,
        flex: 1,
        width: 150,
        float: "right",
        backgroundColor: "var(--primary)",
    },
    deleteButton: {
        color: "white",
        margin: 5,
        flex: 1,
        width: 150,
        float: "left",
        backgroundColor: "var(--red)",
    },
    submitFileButton: {
        color: "white",
        marginTop: 11,
        width: 150,
        backgroundColor: "var(--primary)",
    },
}));

const initialFieldValidation = {
    description: false,
    authority: false,
    program: false,
};

const EditActionForm = () => {
    const [state, login, logout] = useContext(AuthContext);
    const actionId = window.location.href.split("/").pop();
    const [portalTitle, setPortalTitle] = useState("");
    const [portalShortDescription, setPortalShortDescription] = useState("");
    const [portalDescription, setPortalDescription] = useState("");
    const [image, setImage] = useState("");
    const [actionDetails, setActionDetails] = useState([]);
    const [detailsState, setDetailsState] = useState([]);
    const [actionFiles, setActionFiles] = useState([]);
    const [description, setDescription] = useState("");
    const [authority, setAuthority] = useState("");
    const [program, setProgram] = useState("");
    const [fields, setFields] = useState([]);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [fieldValidation, setFieldValidation] = useState(
        initialFieldValidation
    );
    const [filesForUploads, setFilesForUploads] = useState([]);

    const [companyServicesId, setCompanyServicesId] = useState([]);
    const [companySizeId, setCompanySizeId] = useState([]);
    const [regionId, setRegionId] = useState([]);
    const [companyServices, setCompanyServices] = useState([]);
    const [companySizes, setCompanySizes] = useState([]);
    const [regions, setRegions] = useState([]);

    const [loading, setLoading] = useState(true);
    const [clients, setClients] = useState([]);
    const [actions, setActions] = useState([]);
    const [clientActions, setClientActions] = useState([]);
    const [listItems, setListItems] = useState([]);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [endSubmitDate, setEndSubmitDate] = useState();
    const [fileList, setFileList] = useState([]);

    const getCompanyServices = async () => {
        try {
            const response = await api.get("/EsCompanyServices");
            setCompanyServices(response.data);
        } catch (err) {
            alert(err);
        }
    };

    const getCompanySizes = async () => {
        try {
            const response = await api.get("/EsCompanySize");
            setCompanySizes(response.data);
        } catch (err) {
            alert(err);
        }
    };

    const getRegions = async () => {
        try {
            const response = await api.get("/EsRegions");
            setRegions(response.data);
        } catch (err) {
            alert(err);
        }
    };

    useEffect(() => {
        getCompanyServices();
        getCompanySizes();
        getRegions();
    }, []);

    const classes = useStyles();

    const getFields = async () => {
        try {
            const response = await api.get("/EsFields", {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                },
            });
            getAction(response.data);
        } catch (err) {
            alert(err);
        }
    };

    const history = useHistory();

    const getActions = async () => {
        try {
            const response = await api.get("/EsActions", {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                },
            });
            setActions(response.data);
        } catch (err) {
            alert(err);
        }
    };

    const getClients = async () => {
        try {
            const response = await api.get("/EsClient", {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                },
            });
            setClients(response.data);
        } catch (err) {
            alert(err);
        }
    };

    const getClientActions = async () => {
        try {
            const response = await api.get("/EsClientAction", {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                },
            });
            const newList = response.data.filter(
                (item) => item.actionId == actionId
            );
            setClientActions(newList);
        } catch (err) {
            alert(err);
        }
    };

    const compineLists = () => {
        let newList = [];
        clientActions.forEach((clientAction, index) => {
            const obj = {
                index: index + 1,
                id: clientAction.id,
                name: clients.find(
                    (client) => client.id === clientAction.clientId
                ).name,
                description: actions.find(
                    (action) => action.id === clientAction.actionId
                ).description,
                authority: actions.find(
                    (action) => action.id === clientAction.actionId
                ).authority,
                program: actions.find(
                    (action) => action.id === clientAction.actionId
                ).program,
            };
            newList.push(obj);
        });
        setListItems(newList);
        setLoading(false);
    };

    const getActionDetails = async () => {
        try {
            const response = await api.get(`/ActionDetailsByActionId/${actionId}`, {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                },
            });
            const details = response.data;
            setActionDetails(details);
        } catch (err) {
            alert(err);
        }
    };

    useEffect(() => {
        login();
        getActions();
        // getClients();
        // getClientActions();
        getActionDetails();
        // compineLists();
    }, []);

    useEffect(() => {
        if (
            actions.length === 0 ||
            clients.length === 0 ||
            clientActions.length === 0
        ) {
            return;
        }
        compineLists();
    }, [actions, clients, clientActions]);

    const goToEdit = (id) => {
        history.push(`/edit-client-action/${id}`);
    };

    const columns = [
        { field: "index", headerName: "A/A", width: 100 },
        { field: "name", headerName: "Όνομα Πελάτη", flex: 3 },
        { field: "description", headerName: "Τίτλος Δράσης", flex: 1 },
        { field: "authority", headerName: "Φορέας Δράσης", flex: 1 },
        {
            field: "program",
            headerName: "Επιχειρησιακό / Τομεακό Πρόγραμμα",
            flex: 2,
        },
    ];

    const getAction = async (fieldsResponse) => {
        try {
            const response = await api.get(`/EsActions/${actionId}`, {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                },
            });
            const esAction = response.data;
            const esFields = response.data.fields;
            setPortalTitle(esAction.portalTitle);
            setPortalShortDescription(esAction.portalShortDescription);
            setPortalDescription(esAction.portalDescription);
            setDescription(esAction.description);
            setImage(esAction.image);
            setAuthority(esAction.authority);
            setProgram(esAction.program);
            setStartDate(formatDate(esAction.startDate));
            setEndDate(formatDate(esAction.endDate));
            setEndSubmitDate(formatDate(esAction.endSubmitDate));
            //esAction.companySizeId && setCompanySizeId(esAction.companySizeId.split(",").map(Number));
            //esAction.regionId && setRegionId(esAction.regionId.split(",").map(Number));
            //esAction.companyServicesId && setCompanyServicesId(
            //    esAction.companyServicesId.split(",").map(Number)
            //);
            // esFields.forEach((item) => {
            //     const field = fieldsResponse.find((fl) => fl.id === item.id);
            //     field.enabled = true;
            // });
            // setFields(fieldsResponse);
            // setActionFiles(response.data.files);
        } catch (err) {
            console.log(err);
        }
    };

    const formatDate = (date) => {
        return date.substring(0, 10);
    };

    useEffect(() => {
        getFields();
    }, []);

    const submitForm = async () => {
        const currentFieldValidation = {
            description: !description,
            authority: !authority,
            program: !program,
        };
        setFieldValidation(currentFieldValidation);
        const fieldValidationKeys = Object.keys(currentFieldValidation);
        let isFormValid = true;
        fieldValidationKeys.forEach((key) => {
            if (currentFieldValidation[key]) {
                isFormValid = false;
            }
        });
        if (!isFormValid) {
            return;
        }
        try {
            const data = {
                id: actionId,
                portalTitle,
                portalShortDescription,
                portalDescription,
                description,
                image,
                companySizeId: companySizeId.toString(),
                companyServicesId: companyServicesId.toString(),
                regionId: regionId.toString(),
                authority,
                program,
                startDate,
                endDate,
                endSubmitDate,
            };
            const response = await api.put(`/EsActions/${actionId}`, data, {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                },
            });
            const enabledFields = fields.filter(
                (field) => field.enabled === true
            );
            await putActionDetails();
            await uploadFiles();
            setAlertMsg("Η επεξεργασία ολοκληρώθηκε με επιτυχία");
            setShowEditModal(true);
        } catch (err) {
            setAlertMsg("Κάτι πήγε στραβα");
            setShowEditModal(true);
        }
    };

    const uploadFiles = async () => {
        try {
            for (const file of filesForUploads) {
                const data = {
                    name: file.name,
                    description: file.description,
                    filename: file.filename,
                    content: file.content.content,
                    actionId,
                };
                const response = await api.post("/EsFiles/", data, {
                    headers: {
                        Authorization: "Bearer " + state.token.accessToken,
                    },
                });
            }
        } catch (err) {
            alert(err);
        }
    };

    const putActionDetails = async () => {
        const data = {
            id: actionDetails.id,
            actionId,
        };
        detailsState.forEach((field) => {
            data[field.name] = field.value;
        });
        try {
            const response = await api.put(
                `/EsActionDetails/${actionDetails.id}`,
                data,
                {
                    headers: {
                        Authorization: "Bearer " + state.token.accessToken,
                    },
                }
            );
        } catch (err) {
            alert(err);
        }
    };

    const toggleFields = (checked, field) => {
        const fieldsList = [...fields];
        const index = fieldsList.indexOf(field);
        fieldsList[index].enabled = checked;
        setFields(fieldsList);
    };

    const deleteClient = async () => {
        const response = await api.delete(`/EsAction/${actionId}`, {
            headers: {
                Authorization: "Bearer " + state.token.accessToken,
            },
        });
    };

    const setFiles = async (files) => {
        const list = [...filesForUploads];
        for (const file of files) {
            const filenames = filesForUploads.map((img) => img.filename);
            let reader = new FileReader();
            reader.onload = async function () {
                try {
                    const data = {
                        filename: file.path,
                        name: file.name ? file.name : "",
                        description: file.description ? file.description : "",
                        size: file.size,
                        fileType: file.type,
                        extension: file.name.substring(
                            file.name.lastIndexOf(".") + 1
                        ),
                        displayOrder: 1,
                        comments: "IMG Post",
                        content: {
                            tnid: 1,
                            fileType: file.type,
                            extension: file.name.substring(
                                file.name.lastIndexOf(".") + 1
                            ),
                            content: reader.result.split(",")[1],
                        },
                    };

                    list.push(data);
                } catch (err) {
                    alert(err);
                }
            };
            if (file) {
                await reader.readAsDataURL(file);
            }
        }
        setFilesForUploads(list);
    };

    useEffect(() => {
        setFileList([...fileList.concat(acceptedFiles)]);
        setFiles(new Set([...Array.from(acceptedFiles)]));
    }, [acceptedFiles]);

    const downloadFile = (id) => {
        window.open(`${api.defaults.baseURL}EsFile/${id}`);
    };

    const updateFileMetadata = (file, field, value) => {
        const newList = [...actionFiles];
        const index = actionFiles.indexOf(file);
        newList[index][field] = value;
        setActionFiles(newList);
    };

    const files = fileList.map((file, index) => (
        <div key={file.path}>
            <FormControl className={classes.index}>
                <TextField label="Α/Α" disabled value={index + 1} />
            </FormControl>
            <FormControl className={classes.fileDetails}>
                <TextField
                    label="Αρχείο"
                    disabled
                    value={`${file.path} - ${file.size} bytes`}
                />
            </FormControl>
            <FormControl className={classes.fileDetails}>
                <TextField
                    label="Τίτλος Αρχείου"
                    // value={action ? action.description : ""}
                />
            </FormControl>
            <FormControl className={classes.fileDetails}>
                <TextField
                    label="Περιγραφή Αρχείου"
                    // value={action ? action.description : ""}
                />
            </FormControl>
        </div>
    ));

    return (
        <>
            <h4 className={classes.header}>Στοιχεία για το Portal</h4>
            <div className="row">
                <div className="col-md-6 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Τίτλος Δράσης (Εμφανίζεται στο portal)"
                            value={portalTitle}
                            onChange={(event) =>
                                setPortalTitle(event.target.value)
                            }
                        />
                    </FormControl>
                </div>
                <div className="col-md-6 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Σύντομη Περιγραφή (Εμφανίζεται στο portal)"
                            value={portalShortDescription}
                            onChange={(event) =>
                                setPortalShortDescription(event.target.value)
                            }
                        />
                    </FormControl>
                </div>
                <div className="col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Πλήρης Περιγραφή (Εμφανίζεται στο portal)"
                            value={portalDescription}
                            multiline
                            rows={4}
                            rowsMax={20}
                            onChange={(event) =>
                                setPortalDescription(event.target.value)
                            }
                        />
                    </FormControl>
                </div>
                <div className={`col-md-6 col-12 ${classes.imageBox}`}>
                    <ImageBox
                        label="Βασική εικόνα"
                        setImageState={setImage}
                        image={image}
                    />
                </div>
                <ActionDetailsForm
                    setDetailsState={setDetailsState}
                    actionDetails={actionDetails}
                />
            </div>
            <h4 className={classes.header}>Στοιχεία Δράσης</h4>
            <div className="row">
                <div className="col-md-6 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Τίτλος Δράσης"
                            error={fieldValidation.description}
                            value={description}
                            onChange={(event) =>
                                setDescription(event.target.value)
                            }
                        />
                    </FormControl>
                </div>
                <div className="col-md-6 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Φορέας Δράσης"
                            error={fieldValidation.authority}
                            value={authority}
                            onChange={(event) =>
                                setAuthority(event.target.value)
                            }
                        />
                    </FormControl>
                </div>
                <div className="col-md-6 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Επιχειρησιακό/Τομεακό Πρόγραμμα"
                            value={program}
                            error={fieldValidation.program}
                            onChange={(event) => setProgram(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="col-md-6 col-12">
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-helper-label">
                            Μέγεθος Επιχείρησης
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={companySizeId}
                            multiple
                            error={fieldValidation.companySize}
                            onChange={(event) =>
                                setCompanySizeId(event.target.value)
                            }
                        >
                            {companySizes.map((size, index) => (
                                <MenuItem key={index} value={size.id}>
                                    {size.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-6 col-12">
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-helper-label">
                            Αντικείμενο Επιχείρησης
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            error={fieldValidation.companyServices}
                            value={companyServicesId}
                            multiple
                            onChange={(event) =>
                                setCompanyServicesId(event.target.value)
                            }
                        >
                            {companyServices.map((service, index) => (
                                <MenuItem key={index} value={service.id}>
                                    {service.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-6 col-12">
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-helper-label">
                            Περιφέρεια
                        </InputLabel>
                        <Select
                            value={regionId}
                            error={fieldValidation.region}
                            multiple
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            onChange={(event) =>
                                setRegionId(event.target.value)
                            }
                        >
                            {regions.map((region, index) => (
                                <MenuItem key={index} value={region.id}>
                                    {region.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-6 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Ημερομηνία Έναρξης"
                            value={startDate}
                            InputLabelProps={{ shrink: true }}
                            type={"date"}
                            onChange={(event) =>
                                setStartDate(event.target.value)
                            }
                        />
                    </FormControl>
                </div>
                <div className="col-md-6 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Ημερομηνία Λήξης"
                            value={endDate}
                            InputLabelProps={{ shrink: true }}
                            type={"date"}
                            onChange={(event) => setEndDate(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="col-md-6 col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Ημερομηνία Λήξης Υποβολών"
                            value={endSubmitDate}
                            InputLabelProps={{ shrink: true }}
                            type={"date"}
                            onChange={(event) =>
                                setEndSubmitDate(event.target.value)
                            }
                        />
                    </FormControl>
                </div>
            </div>
            <h4 className={classes.header}>Επιλογή Πεδίων Δράσης</h4>
            <div className="row">
                {fields.map((field, index) => (
                    <div className="col-md-6 col-12" key={index}>
                        <FormControl className={classes.formControl}>
                            <FormControlLabel
                                // className={classes.switchRenew}
                                control={
                                    <Switch
                                        name="checkedB"
                                        checked={field.enabled}
                                        // checked={property && property.active}
                                        // color="primary"
                                        onChange={(e) => {
                                            toggleFields(
                                                e.target.checked,
                                                field
                                            );
                                        }}
                                    />
                                }
                                label={field.label}
                            />
                        </FormControl>
                    </div>
                ))}
            </div>
            <h4 className={classes.header}>Αρχεία Υποβολής</h4>
            <div className="col-12">
                {actionFiles.map((file, index) => (
                    <div key={index}>
                        <FormControl className={classes.index}>
                            <TextField label="Α/Α" disabled value={index + 1} />
                        </FormControl>
                        <FormControl className={classes.fileDetails}>
                            <TextField
                                label="Αρχείο"
                                disabled
                                value={file.filename ? file.filename : ""}
                            />
                        </FormControl>
                        <FormControl className={classes.fileDetails}>
                            <TextField
                                label="Τίτλος Αρχείου"
                                value={file.name ? file.name : ""}
                                onChange={(event) =>
                                    updateFileMetadata(
                                        file,
                                        "name",
                                        event.target.value
                                    )
                                }
                            />
                        </FormControl>
                        <FormControl className={classes.fileDetails}>
                            <TextField
                                label="Περιγραφή Αρχείου"
                                value={file.description ? file.description : ""}
                                onChange={(event) =>
                                    updateFileMetadata(
                                        file,
                                        "description",
                                        event.target.value
                                    )
                                }
                            />
                        </FormControl>
                        <FormControl className={classes.fileDetails}>
                            <Button
                                className={classes.submitFileButton}
                                color="primary"
                                onClick={() => downloadFile(file.id)}
                                Large
                            >
                                Download
                            </Button>
                        </FormControl>
                    </div>
                ))}
            </div>
            <h4 className={classes.header}>
                Προσθήκη Συνοδευτικών Αρχείων Δράσης
            </h4>
            <section className="container">
                <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>
                        Drag 'n' drop some files here, or click to select files
                    </p>
                </div>
                <aside>
                    <ul>{files}</ul>
                </aside>
            </section>
            <h4 className={classes.header}>Υποβολές Πελατών στη Δράση</h4>
            <div style={{ height: 530, width: "100%", cursor: "pointer" }}>
                <DataGrid
                    sortModel={[
                        {
                            field: "name",
                            sort: "asc",
                        },
                    ]}
                    rows={listItems}
                    columns={columns}
                    pageSize={8}
                    onRowClick={(event) => goToEdit(event.id)}
                    // checkboxSelection
                    loading={loading}
                />
            </div>
            <div className="col-md-6 col-12">
                <FormControl className={classes.buttonsFormControl}>
                    <Button
                        color="primary"
                        className={classes.deleteButton}
                        onClick={() => {
                            setAlertMsg(
                                "Είστε σίγουροι ότι θέλετε να προχωρήσετε στη διαγραφή της Δράσης;"
                            );
                            setShowDeleteModal(true);
                        }}
                    >
                        Διαγραφη
                    </Button>
                    <Button
                        color="primary"
                        className={classes.submitButton}
                        onClick={() => submitForm()}
                    >
                        Αποθηκευση
                    </Button>
                </FormControl>
            </div>
            <FormModal
                showModal={showEditModal}
                setShowModal={setShowEditModal}
                cancelButton={false}
                deleteButton={false}
                confirmButton={true}
                title={"Επεξεργασία Δράσης"}
                message={alertMsg}
                redirectUrl={"actions"}
            />
            <FormModal
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                cancelButton={true}
                deleteButton={true}
                confirmButton={false}
                title={"Διαγραφή Δράσης"}
                message={alertMsg}
                deleteItem={deleteClient}
                redirectUrl={"actions"}
            />
        </>
    );
};

export default EditActionForm;
