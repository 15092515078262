import React, { useState, useReducer } from "react";
import { Container, Row, Col } from "reactstrap";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormModal from "../components/FormModal";
import { makeStyles } from "@material-ui/core/styles";
import api from "../api/api";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
    },
    button: {
        backgroundColor: "#4e4e4e",
        borderColor: "#4e4e4e",
        "&:hover": {
            backgroundColor: "#0069d9",
            borderColor: "#0062cc",
            boxShadow: "none",
        },
    },
}));

const initModalSettings = {
    cancelButton: true,
    confirmButton: false,
    actionButton: true,
    title: "Προσωποποιημένη επικοινωνία",
    message: "Θέλετε να προχωρήσετε στην Προσωποποιημένη Ενημέρωση σχετικά με χρηματοδοτικά εργαλεία που αφορούν την επιχείρησή σας.",
};

const CommunicationForm = (props) => {
    const classes = useStyles();
    const [showModal, setShowModal] = useState(false);

    const subject = props.subject ? props.subject : "Ενημέρωση για το ΕΣΠΑ";
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [companySize, setCompanySize] = useState("");
    const [companyService, setCompanyService] = useState("");
    const [companyRegion, setCompanyRegion] = useState("");
    const [consent, setConsent] = useState(!props.isCallForInfo);
    const [isEmailValid, setIsEmailValid] = useState(true);

    const date = new Date().toLocaleDateString();

    const [message, setMessage] = useState("");

    const data = {
        consent,
        subject: (!consent)? subject : ` | Ενημέρωση για το ΕΣΠΑ` ,
        firstName,
        lastName,
        phone,
        email,
        companyService,
        companyRegion,
        companySize,
        date,
    };

    const submit = async (data) => {
        if (data.consent) {
            const response = await api.post("/EsNewsletters", data);
        }
        if (props.isCallForInfo) {
            const resp = await api.post("/EsInfo", data);
        }
        dispatch({
            type: "set_cancel_Button",
            payload: false,
        });
        dispatch({
            type: "set_action",
            payload: resetModal,
        });
        dispatch({
            type: "set_message",
            payload: "Σύντομα ένα εκπρόσωπός μας θα επικοινωνήσει μαζί σας",
        });
    };

    const modalReducer = (state, action) => {
        switch (action.type) {
            case "set_action":
                return { ...state, action: action.payload };
            case "set_cancel_Button":
                return { ...state, cancelButton: action.payload };
            case "set_confirm_Button":
                return { ...state, confirmButton: action.payload };
            case "set_action_Button":
                return { ...state, actionButton: action.payload };
            case "set_title":
                return { ...state, title: action.payload };
            case "set_message":
                return { ...state, message: action.payload };
            case "reset":
                return { ...initModalSettings, action: submit };
            default:
                return state;
        }
    };

    function valid_email(email) {
        return email.match(
            /^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i
        );
    }

    const [modalSettngs, dispatch] = useReducer(modalReducer, {
        ...initModalSettings,
        action: submit,
    });

    const resetModal = () => {
        setShowModal(false);
        dispatch({
            type: "reset",
        });
    };

    return (
        <section className="services" id={props.id}>
            <Container>
                <Row>
                    <Col lg="8" md="8" className="offset-lg-2 offset-md-2">
                        <div className="title">
                            <div className="main-title">
                                <h2>{props.title}</h2>
                            </div>
                            <div className="sub-title">
                                <p className="para">{props.subTitle}</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg="4" md="12" className="offset-lg-2">
                        <FormControl className={classes.formControl}>
                            <TextField
                                label="Όνομα"
                                value={firstName}
                                onChange={(event) =>
                                    setFirstName(event.target.value)
                                }
                            />
                        </FormControl>
                    </Col>
                    <Col lg="4" md="12">
                        <FormControl className={classes.formControl}>
                            <TextField
                                label="Επίθετο"
                                value={lastName}
                                onChange={(event) =>
                                    setLastName(event.target.value)
                                }
                            />
                        </FormControl>
                    </Col>
                    <Col lg="4" md="12" className="offset-lg-2">
                        <FormControl className={classes.formControl}>
                            <TextField
                                error={!isEmailValid}
                                label="Email"
                                required
                                value={email}
                                onChange={(event) => {
                                    setIsEmailValid(
                                        valid_email(event.target.value)
                                    );
                                    setEmail(event.target.value);
                                }}
                            />
                        </FormControl>
                    </Col>
                    <Col lg="4" md="12">
                        <FormControl className={classes.formControl}>
                            <TextField
                                label="Tηλέφωνο"
                                value={phone}
                                onChange={(event) =>
                                    setPhone(event.target.value)
                                }
                            />
                        </FormControl>
                    </Col>
                    <Col lg="4" md="12" className="offset-lg-2">
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-helper-label">
                                Μέγεθος Επιχείρησης
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={companySize}
                                onChange={(event) =>
                                    setCompanySize(event.target.value)
                                }
                            >
                                {props.companySizes.map((size, index) => (
                                    <MenuItem key={index} value={size.name}>
                                        {size.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col lg="4" md="12">
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-helper-label">
                                Αντικείμενο Επιχείρησης
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={companyService}
                                onChange={(event) =>
                                    setCompanyService(event.target.value)
                                }
                            >
                                {props.companyServices.map((service, index) => (
                                    <MenuItem key={index} value={service.name}>
                                        {service.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col lg="4" md="12" className="offset-lg-2">
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-helper-label">
                                Περιφέρεια
                            </InputLabel>
                            <Select
                                value={companyRegion}
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                onChange={(event) =>
                                    setCompanyRegion(event.target.value)
                                }
                            >
                                {props.regions.map((region, index) => (
                                    <MenuItem key={index} value={region.name}>
                                        {region.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                    {props.isCallForInfo && (
                        <Col
                            lg="12"
                            md="12"
                            className="offset-lg-2"
                            style={{ marginLeft: 175, color: "#4e4e4e" }}
                        >
                            <FormControlLabel
                                className={classes.formControl}
                                control={
                                    <Checkbox
                                        name="checkedB"
                                        color="primary"
                                        checked={consent}
                                        onChange={(e) =>
                                            setConsent(e.target.checked)
                                        }
                                    />
                                }
                                label={
                                    "Συναινώ να ενημερώνομαι για τις νέες δράσεις ΕΣΠΑ"
                                }
                            />
                        </Col>
                    )}
                    <Col lg="12" md="12" className="offset-lg-2">
                        <p className={classes.formControl}>
                            E-mail επικοινωνίας:{" "}
                            <a
                                href="mailTo:info@businessinone.gr"
                                target="_blank"
                            >
                                info@businessinone.gr
                            </a>
                        </p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg="12" md="12" className="text-center mt-5">
                        <Button
                            variant="contained"
                            disabled={!isEmailValid}
                            className={classes.button}
                            color="primary"
                            style={{
                                minWidth: 500,
                            }}
                            onClick={() => {
                                //submit();
                                setShowModal(true);
                            }}
                        >
                            Αποστολη
                        </Button>
                    </Col>
                </Row>
            </Container>
            <FormModal
                action={modalSettngs.action}
                data={data}
                showModal={showModal}
                setShowModal={setShowModal}
                cancelButton={modalSettngs.cancelButton}
                confirmButton={modalSettngs.confirmButton}
                actionButton={modalSettngs.actionButton}
                title={modalSettngs.title}
                message={modalSettngs.message}
            />
        </section>
    );
};

export default CommunicationForm;
