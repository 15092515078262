import React from "react";
import DashboardLayout from "./DashboardLayout";
import EditClientForm from "./EditClientForm";

export default function NewAction() {
  return (
    <DashboardLayout title={"Επεξεργασία Πελάτη"}>
      <EditClientForm />
    </DashboardLayout>
  );
}
