import React from "react";
import DashboardLayout from "./DashboardLayout";
import NewsletterList from "./NewsletterList";

export default function NewAction() {
  return (
    <DashboardLayout title={"Newsletter List"}>
      <NewsletterList />
    </DashboardLayout>
  );
}
