import React, { useEffect, useState, useContext } from "react";
import { Context as AuthContext } from "../context/authenticationProvider";
import { useHistory } from "react-router-dom";
import DataGridToolbar from "./DataGridToolbar";
import { DataGrid } from "@mui/x-data-grid";
import api from "../api/api";

export default function ClientsList() {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [state, login, logout] = useContext(AuthContext);
  const history = useHistory();

  const getClients = async () => {
    try {
      const response = await api.get("/EsClients", {
        headers: {
          Authorization: "Bearer " + state.token.accessToken,
        },
      });
      response.data.forEach((item, index) => {
        item.index = index + 1;
      });
      setClients(response.data);
      setLoading(false);
    } catch (err) {
      alert(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    login();
    getClients();
  }, []);

  const goToEdit = (id) => {
    history.push(`/edit-client/${id}`);
  };

  const columns = [
    { field: "index", headerName: "A/A", width: 100 },
    { field: "name", headerName: "Επωνυμία Επιχείρησης", flex: 2 },
    { field: "contactPerson", headerName: "Contact Person", flex: 1 },
    { field: "email", headerName: "E-mail", flex: 1 },
    { field: "address", headerName: "Διεύθυνση", flex: 1 },
    { field: "phone", headerName: "Tηλέφωνο", flex: 1 },
    { field: "vat", headerName: "ΑΦΜ", flex: 1 },
  ];

  return (
    <React.Fragment>
      <div style={{ height: 530, width: "100%", cursor: "pointer" }}>
        <DataGrid
          rows={clients}
          columns={columns}
          pageSize={20}
          components={{
            Toolbar: DataGridToolbar,
          }}
          onRowClick={(event) => goToEdit(event.id)}
          // checkboxSelection
          loading={loading}
        />
      </div>
    </React.Fragment>
  );
}
