import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
    },
    search: {
        // background: "gold",
        paddingBottom: 120,
    },
}));

const QuickSearch = (props) => {
    const classes = useStyles();
    const [companyServicesId, setCompanyServicesId] = useState(0);
    const [companySizeId, setCompanySizeId] = useState(0);
    const [regionId, setRegionId] = useState(0);

    return (
        <section className={classes.search} id={props.id}>
            <Container>
                <Row>
                    <Col lg="8" md="8" className="offset-lg-2 offset-md-2">
                        <div className="title">
                            <div className="main-title">
                                <h2>Γρήγορη Αναζήτηση</h2>
                            </div>
                            <div className="sub-title">
                                <p className="para">
                                    Βρείτε με λίγα click τα χρηματοδοτικά
                                    εργαλεία που αφορούν την επιχείρησή σας.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col lg="3" md="3">
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-helper-label">
                                Μέγεθος Επιχείρησης / Ιδιώτες
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={companySizeId}
                                onChange={(event) =>
                                    setCompanySizeId(event.target.value)
                                }
                            >
                                {props.companySizes.map((size, index) => (
                                    <MenuItem key={index} value={size.id}>
                                        {size.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col lg="3" md="3">
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-helper-label">
                                Αντικείμενο Επιχείρησης
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={companyServicesId}
                                onChange={(event) =>
                                    setCompanyServicesId(event.target.value)
                                }
                            >
                                {props.companyServices.map((service, index) => (
                                    <MenuItem key={index} value={service.id}>
                                        {service.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col lg="3" md="3">
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-helper-label">
                                Περιφέρεια
                            </InputLabel>
                            <Select
                                value={regionId}
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                onChange={(event) =>
                                    setRegionId(event.target.value)
                                }
                            >
                                {props.regions.map((region, index) => (
                                    <MenuItem key={index} value={region.id}>
                                        {region.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col lg="3" md="3" className="mt-3">
                        <Link
                            to={`/results?active=True&servicesId=${companyServicesId}&regionId=${regionId}&sizeId=${companySizeId}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                        >
                            <Button variant="contained" color="primary">
                                Αναζητηση
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default QuickSearch;
