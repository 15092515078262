import React from "react";
import { Container, Row, Col } from "reactstrap";
import CardWrapper from "./CardGridWrapper";
import ArticleBox2 from "./ArticleBox2";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const SearchActions = (props) => {
    const actions = props.actions;
    const moreUrl = props.urlParams ? `results?${props.urlParams}` : "results";
    return (
        <section
            className={`${props.className || ""} agency blog blog-sec"`}
            id={props.id}
        >
            <div className="results-header">
                <Row>
                    <Col lg="6" md="8" className="offset-lg-3 offset-md-2">
                        <h2
                        >
                            {props.title}
                        </h2>
                    </Col>
                </Row>
            </div>
            <Container>
                <Row>
                    {actions === null && (
                        <Col sm="12">
                            <p style={{ textAlign: "center" }}>Loading...</p>
                        </Col>
                    )}
                    {actions &&
                        actions.length > 0 &&
                        actions.map((item, index) => (
                            <ArticleBox2
                                key={`grid-no-sidebar-${index}`}
                                action={item}
                                isFullWidth
                                url={props.url}
                            />
                        ))}
                    {actions && actions.length === 0 && (
                        <Col sm="12">
                            <p style={{ textAlign: "center" }}>
                                Δεν υπάρχουν ενεργές δράσεις με τα κριτήρια που
                                επιλέξατε.
                            </p>
                        </Col>
                    )}
                </Row>
            </Container>
        </section>
    );
};

export default SearchActions;
