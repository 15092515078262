import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

const InnerAbout = (props) => {
    return (
        props.details && (
            <Container id={props.id}>
                <Row className={"inner-page-sections-row"}>
                    <Col sm={10}>
                        <h4>{props.details.header}</h4>
                        <div className="body-text">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: props.details.description,
                                }}
                            ></p>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    );
};

export default InnerAbout;
