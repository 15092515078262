import React, { useEffect, useState, useContext } from "react";
import FormControl from "@material-ui/core/FormControl";
import { Container, Row, Col } from "reactstrap";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: "100%",
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	header: {
		margin: "30px 0 10px 7px",
	},
	modal: {
		display: "flex",
		padding: theme.spacing(1),
		alignItems: "center",
		justifyContent: "center",
	},
	paper: {
		width: 750,
		height: 500,
		alignItems: "center",
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		borderRadius: "5px",
	},
	buttons: {
		margin: '25px -13px',
	},
	submitButton: {
		margin: 0,
		flex: 1,
		width: "100%",
		float: "right",
	},
	deleteButton: {
		color: "white",
		margin: 0,
		flex: 1,
		width: "100%",
		float: "left",
		backgroundColor: "var(--red)",
	},
	actionButton: {
		margin: 0,
		flex: 1,
		width: "100%",
		float: "right",
	},
}));

const FormModal = (props) => {
	const classes = useStyles();
	return (
		<Modal
			disableEnforceFocus
			disableAutoFocus
			open={props.showModal}
			className={classes.modal}
		>
			<Container className={classes.paper}>
				<Row style={{ alignItems: "center", height: "100%" }}>
					<Col sm={6}>
						<img alt="" className="img-fluid" src="assets/logo.png" />
					</Col>
					<Col sm={6}>
						<div>
							<Row>
								<Col sm={12}>
									<h2 id="server-modal-title">{props.title}</h2>
									<p id="server-modal-description">{props.message}</p>
								</Col>
							</Row>
							<Row className={classes.buttons}>
								{props.cancelButton && (
									<Col sm={6}>
										<Button
											variant="contained"
											className={classes.submitButton}
											onClick={() => props.setShowModal(false)}
										>
											Ακυρωση
										</Button>
									</Col>
								)}
								{props.deleteButton && (
									<Col sm={6}>
										<Button
											className={classes.deleteButton}
											onClick={async () => {
												props.setShowModal(false);
												await props.deleteItem();
												window.location.href = `/${props.redirectUrl}`;
											}}
										>
											Διαγραφη
										</Button>
									</Col>
								)}
								{props.confirmButton && (
									<Col sm={6}>
										<Button
											className={classes.submitButton}
											style={{ width: "100%" }}
											onClick={() => {
												props.setShowModal(false);
												window.location.href = `/${props.redirectUrl}`;
											}}
										>
											OK
										</Button>
									</Col>
								)}
								{props.actionButton && (
									<Col sm={6}>
										<Button
											color="primary"
											variant="contained"
											className={classes.actionButton}
											onClick={() => {
												props.action(props.data);
											}}
										>
											ΣΥΝΕΧΕΙΑ
										</Button>
									</Col>
								)}
							</Row>
						</div>
					</Col>
				</Row>
			</Container>
		</Modal>
	);
};

export default FormModal;
