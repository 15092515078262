import React from "react";

const ActionTitle = (props) => {
	return (
		<React.Fragment>
			<section className={"action-title-section"}>
				<h6>{props.title}</h6>
			</section>
		</React.Fragment>
	);
};

export default ActionTitle;
