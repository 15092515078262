import React, { useEffect, useState, useContext } from "react";
import { Context as AuthContext } from "../context/authenticationProvider";
import { DataGrid } from '@mui/x-data-grid';
import { useHistory } from "react-router-dom";
import DataGridToolbar from "./DataGridToolbar";
import api from "../api/api";

export default function ActionsList() {
  const [state, login, logout] = useContext(AuthContext);
  const history = useHistory();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] =useState(true);

  const getBlogs = async () => {
    try {
      const response = await api.get("/EsBlogs", {
          headers: {
              Authorization: "Bearer " + state.token.accessToken,
          },
      });
      response.data.forEach((item, index) => {
        item.index = index + 1;
      })
      setBlogs(response.data);
    } catch (err) {
      alert(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    login();
    getBlogs();
  }, []);

  const goToEdit = (id) => {
    history.push(`/edit-blog/${id}`);
  };

  const columns = [
    { field: "index", headerName: "A/A", width: 100 },
    { field: "title", headerName: "Τίτλος", flex: 1 },
    { field: "date", headerName: "Ημερομηνία", flex: 1 },
  ];

  return (
    <React.Fragment>
      <div style={{ height: 530, width: "100%", cursor: 'pointer' }}>
        <DataGrid
          rows={blogs}
          columns={columns}
          components={{
            Toolbar: DataGridToolbar,
          }}
          pageSize={20}
          onRowClick={(event) => goToEdit(event.id)}
          // checkboxSelection
          loading={loading}
        />
      </div>
    </React.Fragment>
  );
}
