import React, { useEffect, useState } from "react";
import Footer from "./common/footer";
import HomeHeader from "./HomeHeader";
import CommunicationForm from "./CommunicationForm";
import Typography from "@material-ui/core/Typography";
import InnerBanner from "./InnerBanner";
import ActionTitle from "./ActionTitle";
import Grid from "@material-ui/core/Grid";
import api from "../api/api";

const BlogPage = () => {
	const blogId = window.location.href.split("/").pop();
	const [title, setTitle] = useState("");
	const [date, setDate] = useState(null);
	const [content, setContent] = useState("");
	const [image, setImage] = useState("");
	const [scrollTop, setScrollTop] = useState(0);

	const [companySizes, setCompanySizes] = useState([]);
	const [companyServices, setCompanyServices] = useState([]);
	const [regions, setRegions] = useState([]);

	const [isReady, setIsReady] = useState(false);

	const getCompanyServices = async () => {
		try {
			const response = await api.get("/EsCompanyServices");
			setCompanyServices(response.data);
		} catch (err) {
			alert(err);
		}
	};

	const getCompanySizes = async () => {
		try {
			const response = await api.get("/EsCompanySize");
			setCompanySizes(response.data);
		} catch (err) {
			alert(err);
		}
	};

	const getRegions = async () => {
		try {
			const response = await api.get("/EsRegions");
			setRegions(response.data);
		} catch (err) {
			alert(err);
		}
	};

	const getBlog = async () => {
		try {
			const response = await api.get(`/EsBlogs/${blogId}`);
			const blog = response.data;
			setTitle(blog.title);
			setDate(blog.date);
			setContent(blog.content);
			setImage(blog.image);
			setIsReady(true);
			window.scrollTo(0, 0);
		} catch (err) {
			alert(err);
		}
	};

	useEffect(() => {
		const onScroll = (e) => {
			setScrollTop(e.target.documentElement.scrollTop);
		};
		window.addEventListener("scroll", onScroll);

		return () => window.removeEventListener("scroll", onScroll);
	}, [scrollTop]);

	useEffect(() => {
		getBlog();
		getCompanyServices();
		getCompanySizes();
		getRegions();
	}, []);

	const blogAction = {
		id: blogId,
		portalTitle: title,
		image: image,
		date: date,
	};

	return (
		<React.Fragment>
			<HomeHeader className="app1" />
			{isReady && <InnerBanner action={blogAction} type={"blog"} />}
			{isReady && scrollTop > 450 && (
				<ActionTitle title={blogAction.portalTitle} />
			)}
			<section
				className="info-section"
				style={{
					margin: "0 auto",
					maxWidth: "1100px",
					padding: "50px 0",
				}}
			>
				<p
					dangerouslySetInnerHTML={{
						__html: content,
					}}
				></p>
			</section>
			<CommunicationForm
				id="form"
				regions={regions}
				title={"Φόρμα Επικοινωνίας"}
				subTitle={
					"Συμπληρώστε τα στοιχεία και σύντομα ένας εκπρόσωπός μας θα επικοινωνήσει μαζί σας."
				}
				companySizes={companySizes}
				companyServices={companyServices}
			/>
			<Footer />
		</React.Fragment>
	);
};

export default BlogPage;
