import React from "react";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
	root: {
		maxWidth: 345,
	},
});

const maxFileSizeInBytes = 1300000;

const ImageBoxCDN = (props) => {
	const imageExists = props.image;
	const classes = useStyles();

	let image = props.image && props.image[0]
		? URL.createObjectURL(props.image[0])
		: (props.id)
			? `https://resnovae.blob.core.windows.net/businessinone/action-main-image${props.id}.png`
			: null;

	console.log(image, props.image, typeof props.image);

	return (
		<>
			{image && props.image!=="" ? (
				<Card className={classes.root}>
					<CardActionArea>
						<CardMedia
							component="img"
							alt="Contemplative Reptile"
							height="140"
							image={image}
							title={props.label}
						/>
						<CardContent>
							<Typography
								gutterBottom
								variant="h5"
								component="h2"
							>
								{props.label}
							</Typography>
						</CardContent>
					</CardActionArea>
					<CardActions>
						<Button
							size="small"
							color="primary"
							onClick={() => props.setImageState("")}
						>
							Delete
						</Button>
					</CardActions>
				</Card>
			) : (
				<FormControl className={""}>
					<Button
						variant="contained"
						color="primary"
						component="label"
					>
						{props.label}
						<input
							type="file"
							hidden
							onChange={(event) => {
								if (
									event.target.files[0].size >
									maxFileSizeInBytes
								) {
									alert(
										"To μέγετος της εικόνας δεν μπορεί να υπερβαίνει το 1MB."
									);
									return;
								}
								props.setImageState(event.target.files);
							}}
						/>
					</Button>
				</FormControl>
			)}
		</>
	);
};

export default ImageBoxCDN;
