import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: "100%",
	},
	buttonsFormControl: {
		margin: theme.spacing(1),
		minWidth: "100%",
		display: "inline-table",
	},
	submitButton: {
		color: "white",
		margin: 5,
		flex: 1,
		width: 150,
		float: "left",
		backgroundColor: "var(--primary)",
	},
}));

const ActionFieldsForm = (props) => {
	const classes = useStyles();
	return (
		<React.Fragment>
			<div className="row">
				{props.fields.map((field, index) => (
					<div className="col-md-6 col-12" key={index}>
						<FormControl className={classes.formControl}>
							<FormControlLabel
								// className={classes.switchRenew}
								control={
									<Switch
										name="checkedB"
										// checked={property && property.active}
										// color="primary"
										onChange={(e) => {
											props.toggleFields(
												e.target.checked,
												field
											);
										}}
									/>
								}
								label={field.label}
							/>
						</FormControl>
					</div>
				))}
			</div>
			<div className="col-md-6 col-12">
				<FormControl className={classes.buttonsFormControl}>
					<Button
						className={classes.submitButton}
						variant="outlined"
						color="primary"
						onClick={() => props.postActionFields()}
					>
						ΕΠΟΜΕΝΟ
					</Button>
				</FormControl>
			</div>
		</React.Fragment>
	);
};

export default ActionFieldsForm;
