import React, { useState } from 'react';
import InnerNav from './InnerNav';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

const InnerHeader = (props) => {
    const [show, setShow] = useState(false)
    const [sidebar, setSidebar] = useState(false);
    const [search, setSearch] = useState(false);

    const clickSidebar = () => {
        setSidebar(!sidebar)
        document.querySelector('.navbar').classList.add('openSidebar')
    }

    return (
        <header className={`app1 loding-header custom-scroll home  `}>
            <Container>
                <Row>
                    <Col>
                        <nav>
                            <a className="m-r-auto" href="/">
                                <img
                                    alt=""
                                    className="logo"
                                    src="/assets/logo.png"
                                />
                            </a>
                            <div className="responsive-btn">
                                <a
                                    className="toggle-nav"
                                    onClick={clickSidebar}
                                >
                                    <i
                                        aria-hidden="true"
                                        className="fa fa-bars text-white"
                                    ></i>
                                </a>
                            </div>
                            <InnerNav menu={props.menu} />
                        </nav>
                    </Col>
                </Row>
            </Container>
        </header>
    );
};

export default InnerHeader;
