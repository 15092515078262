import React from "react";
import { Col } from "reactstrap";

const SlideBox = (props) => {
    const action = props.action;
    if (!action) {
        return <></>;
    }

    return (
        <Col
            lg="3"
            md="3"
            className={
                props.index === props.selected
                    ? "slider-box active"
                    : "slider-box"
            }
            onClick={() => props.setSelected(props.index)}
        >
            <div className="center-text">
                <div className="">
                    <div className="header-text">
                        <p className="text-white">{action.portalTitle}</p>
                    </div>
                </div>
            </div>
        </Col>
    );
};

export default SlideBox;
