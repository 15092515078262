import React from "react";
import DashboardLayout from "./DashboardLayout";
import BlogList from "./BlogList";

export default function NewAction() {
  return (
    <DashboardLayout title={"Blog Posts"}>
      <BlogList />
    </DashboardLayout>
  );
}
