import React from "react";
import DashboardLayout from "./DashboardLayout";
import ClientActionsList from "./ClientActionsList";

export default function NewAction() {
  return (
    <DashboardLayout title={"Υποβολές Πελατών"}>
      <ClientActionsList />
    </DashboardLayout>
  );
}
