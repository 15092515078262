import React, { useState } from "react";
import Nav from "./nav";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Chip from "@material-ui/core/Chip";
const HomeHeader = (props) => {
    const [show, setShow] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [search, setSearch] = useState(false);

    const clickSidebar = () => {
        setSidebar(!sidebar);
        document.querySelector(".navbar").classList.add("openSidebar");
    };

    return (
        <header className={`app1 loding-header custom-scroll home  `}>
            <Container>
                <Row>
                    <Col>
                        <nav>
                            <a className="m-r-auto" href="/">
                                <img
                                    alt=""
                                    className="logo"
                                    src="assets/logo.png"
                                />
                                <Chip className={"beta-icon"} label="BETA" />
                            </a>
                            <div className="responsive-btn">
                                <a
                                    className="toggle-nav"
                                    onClick={clickSidebar}
                                >
                                    <i
                                        aria-hidden="true"
                                        className="fa fa-bars text-white"
                                    ></i>
                                </a>
                            </div>
                            <Nav />
                        </nav>
                    </Col>
                </Row>
            </Container>
        </header>
    );
};

export default HomeHeader;
