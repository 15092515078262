import React from "react";
import DashboardLayout from "./DashboardLayout";
import ClientForm from "./ClientForm";

export default function NewAction() {
  return (
    <DashboardLayout title={"Προσθήκη Πελάτη"}>
      <ClientForm />
    </DashboardLayout>
  );
}
