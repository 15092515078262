import React, { useEffect, useState, useContext } from "react";
import { Context as AuthContext } from "../context/authenticationProvider";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import FormModal from "./FormModal";
import ImageBox from "../components/ImageBox";
import ReactQuill from "react-quill";
import Delta from "quill-delta";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import api from "../api/api";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        margin: "30px 0 10px 7px",
    },
    buttonsFormControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        display: "inline-table",
    },
    submitButton: {
        color: "white",
        margin: 5,
        flex: 1,
        width: 150,
        float: "left",
        backgroundColor: "var(--primary)",
    },
}));

const BlogForm = () => {
    const classes = useStyles();
    const [state, login, logout] = useContext(AuthContext);
    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [content, setContent] = useState("");
    const [image, setImage] = useState("");
    const [imageToUpload, setImageToUpload] = useState();
    const [alertMsg, setAlertMsg] = useState("");
    const [showModal, setShowModal] = useState(false);

    const submitForm = async () => {
        try {
            const formData = new FormData();
            formData.append("title", title);
            formData.append("date", date);
            formData.append("content", content);
            formData.append("image", image[0]);
            const response = await api.post("/EsBlogs", formData, {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                    "Content-Type": "multipart/form-data",
                },
            });
            setAlertMsg("Το Post καταχορύθηκε με επιτυχία.");
            setShowModal(true);
        } catch (err) {
            setAlertMsg(err);
            setShowModal(true);
        }
    };

    return (
        <>
            <h4 className={classes.header}>Στοιχεία Blog Post</h4>
            <div className="row">
                <div className="col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Τίτλος"
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="col-12">
                    <FormControl className={classes.formControl}>
                        <TextField
                            type="date"
                            label="Ημερομηνία"
                            value={date}
                            onChange={(event) => setDate(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="col-12">
                    <FormControl className={classes.formControl}>
                        <ReactQuill
                            theme="snow"
                            onChange={setContent}
                            value={content}
                        />
                    </FormControl>
                </div>
                <div className="col-md-4 col-12">
                    <FormControl className={classes.formControl}>
                        <ImageBox
                            label={"Εικόνα"}
                            setImageState={(image) => setImage(image)}
                            image={image}
                        />
                    </FormControl>
                </div>
            </div>
            <div className="col-md-12 col-12">
                <FormControl className={classes.buttonsFormControl}>
                    <Button
                        className={classes.submitButton}
                        color="primary"
                        onClick={() => submitForm()}
                    >
                        Αποθήκευση
                    </Button>
                </FormControl>
            </div>
            <FormModal
                showModal={showModal}
                setShowModal={setShowModal}
                cancelButton={false}
                deleteButton={false}
                confirmButton={true}
                title={"Δημιουργία Blog Post"}
                message={alertMsg}
                redirectUrl={"blogs"}
            />
        </>
    );
};

export default BlogForm;
