import React from "react";
import {GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const DataGridToolbar = () => {
	return (
		<GridToolbarContainer>
			<GridToolbarExport />
		</GridToolbarContainer>
	);
};

export default DataGridToolbar;
