import React, { useEffect } from "react";
import { Row, Container, Col } from "reactstrap";
import { Link } from "react-router-dom";
import api from "../api/api";

const DisplayFiles = (props) => {
	const fileDownload = async (id) => {
		var win = window.open('');
		try {
			const response = await api.get(`/DownloadFile/${id}`, {
				responseType: "blob",
			});
			var url = window.URL.createObjectURL(response.data, {
				oneTimeOnly: true,
			});
			win.location = url;
		} catch (err) {
			alert(err);
		}
	};

	return (
		<Container id={props.id}>
			<h4>Συνοδευτικά Αρχεία</h4>
			{props.files.map((file, index) => (
				<Row key={index} className={"inner-page-sections-row"}>
					<Col sm={12}>
						<Link onClick={() => fileDownload(file.id)}>
							{file.name}
						</Link>
					</Col>
				</Row>
			))}
		</Container>
	);
};
export default DisplayFiles;
