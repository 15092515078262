import React, { useReducer, useEffect } from "react";
import * as msal from "@azure/msal-browser";

const Context = React.createContext([{}, () => {}, () => {}, () => {}]);

const tenant = "businessinone.onmicrosoft.com";
const signInPolicy = "B2C_1_SignInSignUp";
const editProfilePolicy = "B2C_1_EditProfile";
const applicationID = "80d4a4d0-a9e5-4f2d-90b2-03ab38841232";
const reactRedirectUri = window.location.origin;
const tenantSubdomain = "businessinone";
const instance = `https://${tenantSubdomain}.b2clogin.com/tfp/`;
const signInAuthority = `${instance}${tenant}/${signInPolicy}`;
const editProfileAuthority = `${instance}${tenant}/${editProfilePolicy}`;

// Msal Configurations
const config = {
    auth: {
        authority: signInAuthority,
        clientId: applicationID,
        redirectUri: reactRedirectUri,
        validateAuthority: false,
        knownAuthorities: [signInAuthority],
        response_type: "code",
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
    },
};

const editProfileConfig = {
    auth: {
        authority: editProfileAuthority,
        clientId: applicationID,
        redirectUri: reactRedirectUri,
        validateAuthority: false,
        knownAuthorities: [editProfileAuthority],
        response_type: "code",
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
    },
};

const authenticationParameters = {
    scopes: [
        "https://businessinone.onmicrosoft.com/api/api",
        "openid",
        "profile",
    ],
};

const msalInstance = new msal.PublicClientApplication(config);
const editProfileMsalInstance = new msal.PublicClientApplication(editProfileConfig);

let account = {};
let defaultState = { isLoggedIn: false };
const loggedInAccounts = msalInstance.getAllAccounts();
const accountUser = JSON.parse(localStorage.getItem('accountUser'));
if (accountUser && (loggedInAccounts.length > 0)) {
    defaultState = accountUser;
}

const authReducer = (state, action) => {
    switch (action.type) {
        case "set_account":
            return { ...state, account: action.payload };
        case "set_login_status":
            return { ...state, isLoggedIn: action.payload };
        case "set_token":
            return { ...state, token: action.payload };
        case "logout":
            return { ...state, account: action.payload };
        case "login":
            return { ...state, account: action.payload.account, isLoggedIn: action.payload.isLoggedIn};
        case "restore":
            return action.payload;
        default:
            return state;
    }
};

const Provider = (props) => {
    const [state, dispatch] = useReducer(authReducer, defaultState);
    useEffect( () => {
        localStorage.setItem('accountUser', JSON.stringify(state));
    }, [state]);

    const login = async () => {
        const accounts = await msalInstance.getAllAccounts();
        const isLoggedIn = accounts.length > 0 ? true : false;
        try {
            account = JSON.parse(localStorage.getItem('account'));
        } catch (err)
        {
            console.log(err)
        }
        if (isLoggedIn && account) {
            dispatch({
                type: "login",
                payload: {account: account, isLoggedIn:true},
            });
            await getToken(account);
            return;
        }
        await msalInstance
            .loginPopup(authenticationParameters)
            .then((loginResponse) => {
                localStorage.setItem('account', JSON.stringify(loginResponse));
                account = loginResponse;
                getToken(loginResponse);
                dispatch({
                    type: "login",
                    payload: {account: account, isLoggedIn:true},
                });
            })
            .catch((error) => {
                alert(error);
                window.location.href = window.location.origin;
            });
    };

    const logout = async () => {
        try {
            await msalInstance.logout();
            localStorage.removeItem('account');
            dispatch({ type: "logout", payload: false });
        } catch (err) {
            alert(err);
        }
    };

    const getToken = async (loginResponse) => {
        if (msalInstance.getAllAccounts()) {
            var tokenRequest = authenticationParameters;
            msalInstance
                .acquireTokenSilent(account)
                .then((response) => {
                    dispatch({ type: "set_token", payload: response });
                    console.log(response);
                })
                .catch((err) => {
                    alert(err);
                    // could also check if err instance of
                    // InteractionRequiredAuthError
                    // if you can import the class.
                    if (err.name === "InteractionRequiredAuthError") {
                        return msalInstance
                            .acquireTokenPopup(tokenRequest)
                            .then((response) => {
                                dispatch({
                                    type: "set_token",
                                    payload: response,
                                });
                                // get access token from response
                                // response.accessToken
                            })
                            .catch((err) => {
                                alert(err);
                            });
                    }
                });
        } else {
            alert("error");
        }
    };

    const editProfile = async () => {
        await editProfileMsalInstance
            .loginPopup(authenticationParameters)
            .then((response) => {
                localStorage.setItem('account', JSON.stringify(response));
                dispatch({
                    type: "set_account",
                    payload: response,
                });
            })
            .catch((error) => {
                alert(error)
            });
    }
 
    return (
        <Context.Provider value={[state, login, logout, editProfile]}>
            {props.children}
        </Context.Provider>
    );
};

export { Context, Provider };
