import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { Provider as AuthProvider } from "./context/authenticationProvider";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { hydrate, render } from "react-dom";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
	hydrate(<HelmetProvider>
		<Helmet
			titleTemplate="%s | Ενημερωτικό Portal"
			defaultTitle="Business In One"
		/>
		<BrowserRouter basename={baseUrl}>
			<AuthProvider>
				<App />
			</AuthProvider>
		</BrowserRouter>
	</HelmetProvider>, rootElement);
} else {
	render(<HelmetProvider>
		<Helmet
			titleTemplate="%s | Ενημερωτικό Portal"
			defaultTitle="Business In One"
		/>
		<BrowserRouter basename={baseUrl}>
			<AuthProvider>
				<App />
			</AuthProvider>
		</BrowserRouter>
	</HelmetProvider>, rootElement);
}

registerServiceWorker();
