import React, { useEffect, useState, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import api from "../api/api";
import { makeStyles } from "@material-ui/core/styles";
import { Context as AuthContext } from "../context/authenticationProvider";
import FormModal from "./FormModal";
import { PinDropSharp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    deleteButton: {
        color: "white",
        margin: 5,
        flex: 1,
        width: 150,
        float: "left",
        backgroundColor: "var(--red)",
    },
    buttonsFormControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        display: "inline-table",
    },
    submitButton: {
        color: "white",
        margin: 5,
        flex: 1,
        width: 150,
        float: "left",
        backgroundColor: "var(--primary)",
    },
}));

const initialFieldValidation = {
    clientId: false,
    actionId: false,
};

const ActionForClientForm = (props) => {
    const [state, login, logout] = useContext(AuthContext);
    const [clients, setClients] = useState([]);
    const [actions, setActions] = useState([]);
    const [clientId, setClientId] = useState(null);
    const [actionId, setActionId] = useState(null);
    const [fieldValidation, setFieldValidation] = useState(
        initialFieldValidation
    );

    const [alertMsg, setAlertMsg] = useState("");
    const [showModal, setShowModal] = useState(false);

    const classes = useStyles();

    const getClients = async () => {
        try {
            const response = await api.get("/EsClients", {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                },
            });
            setClients(response.data);
        } catch (err) {
            alert(err);
        }
    };

    const getActions = async () => {
        try {
            const response = await api.get("/EsActionsWithoutImages", {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                },
            });
            setActions(response.data);
            props.setLoading(false);
        } catch (err) {
            alert(err);
        }
    };

    useEffect(() => {
        getClients();
        getActions();
    }, []);

    const submitForm = async () => {
        const currentFieldValidation = {
            actionId: !actionId,
            clientId: !clientId,
        };
        setFieldValidation(currentFieldValidation);
        const fieldValidationKeys = Object.keys(currentFieldValidation);
        let isFormValid = true;
        fieldValidationKeys.forEach((key) => {
            if (currentFieldValidation[key]) {
                isFormValid = false;
            }
        });
        if (!isFormValid) {
            return;
        }
        try {
            const data = {
                actionId,
                clientId,
            };
            const response = await api.post("/EsClientActions", data, {
                headers: {
                    Authorization: "Bearer " + state.token.accessToken,
                },
            });
            setAlertMsg("Η Υποβολή του Πελάτη καταχορύθηκε με επιτυχία.");
            setShowModal(true);
        } catch (err) {
            setAlertMsg(err);
            setShowModal(true);
        }
    };

    return (
        <>
            <h4>Προσθήκη Δράσης σε Πελάτη</h4>
            <div className="col-md-6 col-12">
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-helper-label">
                        Επιλογή Πελάτη
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        error={fieldValidation.clientId}
                        onChange={(event) => setClientId(event.target.value)}
                    >
                        {clients.map((client, index) => (
                            <MenuItem key={index} value={client.id}>
                                {client.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className="col-md-6 col-12">
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-helper-label">
                        Eπιλογή Δράσης
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        error={fieldValidation.actionId}
                        onChange={(event) => setActionId(event.target.value)}
                    >
                        {actions.map((action, index) => (
                            <MenuItem key={index} value={action.id}>
                                {action.portalTitle}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className="col-md-6 col-12">
                <FormControl className={classes.buttonsFormControl}>
                    <Button
                        className={classes.submitButton}
                        color="primary"
                        onClick={() => submitForm()}
                        Large
                    >
                        Αποθήκευση
                    </Button>
                </FormControl>
            </div>
            <FormModal
                showModal={showModal}
                setShowModal={setShowModal}
                cancelButton={false}
                deleteButton={false}
                confirmButton={true}
                title={"Δημιουργία Yποβολής Πελάτη"}
                message={alertMsg}
                redirectUrl={"client-actions"}
            />
        </>
    );
};

export default ActionForClientForm;
