import React, { useEffect, useState, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import ImageBoxCDN from "../../components/ImageBoxCDN";
import ImageBox from "../../components/ImageBox";
import ReactQuill from "react-quill";
import Delta from "quill-delta";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

const formatDate = (date) => {
	return (date) ? date.substring(0, 10) : null;
};

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: "100%",
	},
	buttonsFormControl: {
		margin: theme.spacing(1),
		minWidth: "100%",
		display: "inline-table",
	},
	submitButton: {
		color: "white",
		margin: 5,
		flex: 1,
		width: 150,
		float: "left",
		backgroundColor: "var(--primary)",
	},
	imageBox: {
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(3),
		minWidth: "100%",
	},
	checkBox: {
		padding: theme.spacing(1),
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	index: {
		margin: theme.spacing(1),
		width: 50,
	},
	fileDetails: {
		margin: theme.spacing(1),
		width: 200,
	},
	header: {
		margin: "30px 0 10px 7px",
	},
}));

const ActionBacicForm = (props) => {
	const classes = useStyles();
	const fields = [
		{
			name: "portalTitle",
			label: "Τίτλος Δράσης (Εμφανίζεται στο portal)",
			value: "",
			type: "text",
			autoFormData: true,
		},
		{
			name: "portalShortDescription",
			label: "Σύντομη Περιγραφή (Εμφανίζεται στο portal)",
			value: "",
			type: "text",
			autoFormData: true,
		},
		{
			name: "portalDescription",
			label: "Πλήρης Περιγραφή (Εμφανίζεται στο portal)",
			value: "",
			type: "text",
			autoFormData: true,
		},
		{
			name: "portal",
			label: "Εμφάνιση στο portal",
			value: false,
			type: "checkbox",
			autoFormData: true,
		},
		{
			name: "slider",
			label: "Εμφάνιση στον slider",
			value: false,
			type: "checkbox",
			autoFormData: true,
		},
		{
			name: "active",
			label: "Ενεργή",
			value: false,
			type: "checkbox",
			autoFormData: true,
		},
		{
			name: "authority",
			label: "Φορέας Δράσης",
			value: "",
			type: "text",
			autoFormData: true,
		},
		{
			name: "program",
			label: "Επιχειρησιακό/Τομεακό Πρόγραμμα",
			value: "",
			type: "text",
			autoFormData: true,
		},
		{
			name: "companySizeId",
			label: "Μέγεθος Επιχείρησης",
			value: [],
			multiple: true,
			listItems: props.companySizes,
			type: "select",
			autoFormData: true,
		},
		{
			name: "companyServicesId",
			label: "Αντικείμενο Επιχείρησης",
			value: [],
			multiple: true,
			listItems: props.companyServices,
			type: "select",
			autoFormData: true,
		},
		{
			name: "regionId",
			label: "Περιφέρεια",
			value: [],
			multiple: true,
			listItems: props.regions,
			type: "select",
			autoFormData: true,
		},
		{
			name: "startDate",
			label: "Ημερομηνία Έναρξης",
			value: "",
			type: "date",
			autoFormData: true,
		},
		{
			name: "endDate",
			label: "Ημερομηνία Λήξης",
			value: "",
			type: "date",
			autoFormData: true,
		},
		{
			name: "endSubmitDate",
			label: "Ημερομηνία Λήξης Υποβολών",
			value: "",
			type: "date",
			autoFormData: true,
		},
		{
			name: "image",
			label: "Βασική Εικόνα",
			value: "",
			type: "image",
		},
	];

	const [state, setState] = useState(fields);

	useEffect(() => {
		if (props.action) {
			const newState = [...state];
			newState.forEach((field) => {
				field.value =
					field.type === "text"
						? props.action[field.name]
						: field.type === "date"
						? formatDate(props.action[field.name])
						: field.type === "select" && props.action[field.name]
						? props.action[field.name].split(",").map(Number)
						: field.type === "select" && !props.action[field.name]
						? []
						: props.action[field.name];
			});
			setState(newState);
			props.setActionBasicState(newState);
		}
	}, [props.action]);

	const updateValue = (name, value) => {
		const newState = [...state];
		const field = newState.find((item) => item.name === name);
		field.value = value;
		setState(newState);
		props.setActionBasicState(newState);
	};

	const [value, setValue] = useState("");
	return (
		<React.Fragment>
			{state.map((field, index) =>
				field.type === "text" ? (
					<div className="col-12" key={index}>
						<FormControl className={classes.formControl}>
							<TextField
								label={field.label}
								value={field.value}
								multiline
								maxRows={20}
								onChange={(event) =>
									updateValue(field.name, event.target.value)
								}
							/>
						</FormControl>
					</div>
				) : field.type === "editor" ? (
					<div className="col-12" key={index}>
						<FormControl className={classes.formControl}>
							{(field.value || field.value === "") && (
								<ReactQuill
									theme="snow"
									onChange={(
										content,
										delta,
										source,
										editor
									) => {
										updateValue(field.name, content);
									}}
									value={field.value}
								/>
							)}
						</FormControl>
					</div>
				) : field.type === "image" ? (
					<div className={classes.imageBox} key={index}>
								<ImageBoxCDN
							id={(props.action && props.action.id) ? props.action.id : null}
							label={field.label}
							setImageState={(image) =>
								updateValue(field.name, image)
							}
							image={field.value}
						/>
					</div>
				) : field.type === "select" ? (
					<div className="col-12" key={index}>
						<FormControl className={classes.formControl}>
							<InputLabel id="demo-simple-select-helper-label">
								{field.label}
							</InputLabel>
							<Select
								labelId="demo-simple-select-helper-label"
								id="demo-simple-select-helper"
								value={field.value}
								multiple
								onChange={(event) =>
									updateValue(field.name, event.target.value)
								}
							>
								{field.listItems.map((item, index) => (
									<MenuItem key={index} value={item.id}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				) : field.type === "date" ? (
					<div className="col-12" key={index}>
						<FormControl className={classes.formControl}>
							<TextField
								label={field.label}
								value={field.value}
								type={"date"}
								InputLabelProps={{ shrink: true }}
								onChange={(event) =>
									updateValue(field.name, event.target.value)
								}
							/>
						</FormControl>
					</div>
				) : (
					<div className="col-12" key={index}>
						<FormControlLabel
							control={
								<Checkbox
									onChange={(event) =>
										updateValue(
											field.name,
											event.target.checked
										)
									}
									checked={field.value}
									name="checkedB"
									color="primary"
								/>
							}
							label={field.label}
							className={classes.checkBox}
						/>
					</div>
				)
			)}
			{
				(props.action && props.action.id) &&
				<div className="col-12">
					<FormControl className={classes.formControl}>
						<TextField
							label={"Social Media Sharing URL"}
							value={`https://businessinone.gr/assets/sharing/${props.action.id}.html`}
							type={"text"}
							InputLabelProps={{ shrink: true }}
							readonly={true}
						/>
					</FormControl>
				</div>
            }
			<div className="col-md-6 col-12">
				<FormControl className={classes.buttonsFormControl}>
					{props.action && (
						<Button
							color="primary"
							variant="contained"
							className={classes.deleteButton}
							onClick={() => {
								props.deleteAction();
							}}
						>
							Διαγραφη
						</Button>
					)}
					<Button
						className={classes.submitButton}
						variant="outlined"
						color="primary"
						onClick={() => props.submitForm()}
					>
						ΕΠΟΜΕΝΟ
					</Button>
				</FormControl>
			</div>
		</React.Fragment>
	);
};
export default ActionBacicForm;
