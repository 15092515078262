import React, { Component } from 'react';
import { Route } from 'react-router';
import NewClient from './components/NewClient';
import NewAction from './components/action/NewAction';
import NewBlog from './components/NewBlog';
import NewClientAction from './components/NewClientAction';
import Clients from './components/Clients';
import Actions from './components/Actions';
import ClientActions from './components/ClientActions';
import EditClientAction from './components/EditClientAction';
import EditClient from './components/EditClient';
import EditAction from './components/action/EditAction';
import EditBlog from './components/EditBlog';
import SignIn from './components/SignIn';
import HomePage from './portal/HomePage';
import InnerPage from './portal/InnerPage';
import ResultsPage from './portal/ResultsPage';
import BlogPage from './portal/BlogPage';
import BlogsPage from './portal/BlogsPage';
import Blogs from './components/Blogs';
import Newsletters from './components/Newsletters';
import Info from './components/Info';
import './portal/assets/css/styles.css';
import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <>
      <Route exact path='/' component={HomePage} />
      <Route path='/blog' component={BlogPage} />
      <Route path='/blogs-results' component={BlogsPage} />
      <Route path='/blogs' component={Blogs} />
      <Route path='/info' component={Info} />
      <Route path='/newsletter' component={Newsletters} />
      <Route path='/blogs-new' component={NewBlog} />
      <Route path='/sign-in' component={SignIn} />
      <Route path='/results' component={ResultsPage} />
      <Route path='/clients-new' component={NewClient} />
      <Route path='/actions-new' component={NewAction} />
      <Route path='/page' component={InnerPage} />
      <Route path='/clients-actions-new' component={NewClientAction} />
      <Route path='/clients' component={Clients} />
      <Route path='/actions' component={Actions} />
      <Route path='/client-actions' component={ClientActions} />
      <Route path='/edit-action' component={EditAction} />
      <Route path='/edit-client-action' component={EditClientAction} />
      <Route path='/edit-blog' component={EditBlog} />
      <Route path='/edit-client' component={EditClient} />
      </>
    );
  }
}
