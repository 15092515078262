import React from "react";
import Tilt from "react-parallax-tilt";
import { Container, Row, Col } from "reactstrap";

const formatDate = (date) => {
    if (!date) {
        return 'ANAMENETAI';
    }
    const newDate = date.substring(0, 10);
    const dateArray = newDate.split("-");
    return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
};

const InnerBanner = (props) => {
    if (!props.action) {
        return <React.Fragment></React.Fragment>;
    }
    const backgroundImage = (props.type === "action") ?
        `https://resnovae.blob.core.windows.net/businessinone/action-main-image${props.action.id}.png` :
        `https://resnovae.blob.core.windows.net/businessinone/blog-image-${props.action.id}.png`;

    return (
        <section
            className="agency header"
            id="header"
            style={{ paddingTop: 86 }}
        >
            <div className="agency bg">
                <Container className="full-height">
                    <Row className="full-height">
                        <Col md="4" className="title-container">
                            <div className="header-text">
                                <h1
                                    style={{
                                        fontSize: "25px",
                                        maxWidth: "90%",
                                        fontWeight: "500",
                                    }}
                                    className=""
                                >
                                    {props.action.portalTitle}
                                </h1>
                            </div>
                            <div className="header-sub-text">
                                {props.type === "action" && props.action.startDate && props.action.endSubmitDate && (
                                    <p>
                                        Διάστημα Υποβολών: Από{" "}
                                        {formatDate(props.action.startDate)} έως{" "}
                                        {formatDate(props.action.endSubmitDate)}
                                    </p>
                                )}
                                {props.type === "action" && (!props.action.startDate || !props.action.endSubmitDate) && (
                                    <p>
                                        Διάστημα Υποβολών: ANAMENETAI
                                    </p>
                                )}
                                {props.type === "blog" && (
                                    <p>
                                        Ημερομηνία:{" "}
                                        {formatDate(props.action.date)}
                                    </p>
                                )}
                            </div>
                            {props.type === "action" && (
                                <div className="header-sub-text">
                                    <p>{props.action.portalShortDescription}</p>
                                </div>
                            )}
                        </Col>
                        <Col
                            md="8"
                            style={{
                                background: `url(${backgroundImage})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        >
                            <div className="center-text"></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
};

export default InnerBanner;
