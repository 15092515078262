import React, { useEffect, useState, useContext } from "react";
import { Context as AuthContext } from "../context/authenticationProvider";
import { DataGrid } from '@mui/x-data-grid';
import DataGridToolbar from "./DataGridToolbar";
import { useHistory } from "react-router-dom";
import api from "../api/api";

export default function ActionsList() {
  const [state, login, logout] = useContext(AuthContext);
  const history = useHistory();
  const [actions, setActions] = useState([]);
    const [loading, setLoading] = useState(true);

    function compareNumbers(a, b) {
        return b - a;
    }

  const getActions = async () => {
    try {
      const response = await api.get("/EsActionsWithoutImages", {
          headers: {
              Authorization: "Bearer " + state.token.accessToken,
          },
      });
      response.data.sort(compareNumbers);
      response.data.forEach((item, index) => {
        item.index = index + 1;
      })
        console.log(response.data)
      setActions(response.data);
    } catch (err) {
      alert(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    login();
    getActions();
  }, []);

  const goToEdit = (id) => {
    history.push(`/edit-action/${id}`);
  };

  const columns = [
    { field: "index", headerName: "A/A", width: 100 },
    { field: "portalTitle", headerName: "Τίτλος Δράσης", flex: 2 },
    { field: "authority", headerName: "Φορέας Δράσης", flex: 1 },
    { field: "program", headerName: "Επιχειρησιακό / Τομεακό Πρόγραμμα", flex: 3 },
  ];

  return (
    <React.Fragment>
      <div style={{ height: 530, width: "100%", cursor: 'pointer' }}>
        <DataGrid
          rows={actions}
          columns={columns}
          pageSize={20}
          components={{
            Toolbar: DataGridToolbar,
          }}
          onRowClick={(event) => goToEdit(event.id)}
          // checkboxSelection
          loading={loading}
        />
      </div>
    </React.Fragment>
  );
}
